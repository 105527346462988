var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { SelectEntryField } from '@samc/react-ui-form';
import { useValidationViewsRequest } from '@samc/screen-config-api';
import { FieldLabel } from '@samc/react-ui-form/lib/atoms/FieldLabel/FieldLabel';
export const ValidationSelect = (props) => {
    const { domainId, validationViewId, onChange } = props;
    const validationViews = useValidationViewsRequest();
    const handleGetValidations = () => __awaiter(void 0, void 0, void 0, function* () {
        return validationViews(domainId);
    });
    const handleOnChange = React.useCallback((menuItem) => {
        onChange(menuItem === null || menuItem === void 0 ? void 0 : menuItem.id);
    }, [onChange]);
    return (React.createElement("div", { style: { width: '300px' }, "data-testid": "validation-select" },
        React.createElement(FieldLabel, { labelText: "Validation" }),
        React.createElement("div", { className: "field-input", style: { width: '100%' } },
            React.createElement(SelectEntryField, { displayProperty: "description", valueProperty: "id", onChange: handleOnChange, onFetchMenuItems: handleGetValidations, defaultSelectedKey: validationViewId }))));
};
export default ValidationSelect;
