import React from 'react';
import { ListViewRecordDropdown } from './ListViewRecordDropdown/ListViewRecordDropdown';
import { DefaultRecordDropdown } from './DefaultRecordDropdown/DefaultRecordDropdown';
// backwards compat
export * from './Constants';
export const RecordDropdown = (props) => {
    const { domainId, filters, listViewId, primaryKeyValue, onValueChanged } = props;
    const selectedKeys = React.useMemo(() => (primaryKeyValue ? [primaryKeyValue] : []), [primaryKeyValue]);
    const onSelectionChanged = React.useCallback((_selectedKeys) => {
        const value = _selectedKeys.at(0);
        onValueChanged(value);
    }, [onValueChanged]);
    if (listViewId)
        return (React.createElement(ListViewRecordDropdown, { listViewId: listViewId, filters: filters, selectedKeys: selectedKeys, onSelectionChanged: onSelectionChanged }));
    return (React.createElement(DefaultRecordDropdown, { domainId: domainId, filters: filters, selectedKeys: selectedKeys, onSelectionChanged: onSelectionChanged }));
};
export default RecordDropdown;
