var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import { FolderDropdownFormField, } from '@samc/folder-ui-core';
import { ToolbarButton } from '@samc/react-ui-core';
import React from 'react';
import { useStyletron } from 'styletron-react';
const _EditableFolderSelectorField = (props, ref) => {
    const { onEdit, editTooltip } = props, forwardedProps = __rest(props, ["onEdit", "editTooltip"]);
    const { inputProps } = props;
    const { value } = inputProps;
    const [css] = useStyletron();
    return (React.createElement("div", { className: css({ display: 'flex', gap: '5px', height: '100%' }) },
        React.createElement(FolderDropdownFormField, Object.assign({ ref: ref }, forwardedProps)),
        typeof value === 'string' && onEdit && (React.createElement(ToolbarButton, { tooltip: editTooltip, onClick: () => onEdit(value), iconName: "edit" }))));
};
export const EditableFolderSelectorField = React.forwardRef(_EditableFolderSelectorField);
export const getEditableFolderSelectorFieldRenderer = (params) => (props, ref) => (React.createElement(EditableFolderSelectorField, { ref: ref, inputProps: props, onEdit: params.onEdit, editTooltip: params.editTooltip, spaceName: params.spaceName, itemType: params.itemType }));
