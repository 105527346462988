var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import SitusHeader from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import React from 'react';
import { DataEntryForm } from '@samc/react-ui-form';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import { ViewSetLayoutFormMappers, getViewsetLayoutSettingsForm } from './Utilities';
const EmptyFn = () => { };
export const ViewSetLayoutSettings = (props) => {
    const { domainId, initialData: initialLayout, onFormSubmit, onFormCancel, onEditFormView, onEditListView } = props;
    const dirtinessScope = React.useRef(null);
    const formView = React.useMemo(() => getViewsetLayoutSettingsForm({
        domainId,
        onEditFormView,
        onEditListView,
    }), [domainId, onEditFormView, onEditListView]);
    const initialData = React.useMemo(() => ViewSetLayoutFormMappers.toFormData(initialLayout), [initialLayout]);
    const { save, promptAndContinue, isDirty } = useDirtinessPrompt({ scope: dirtinessScope });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const layout = ViewSetLayoutFormMappers.fromFormData(data);
        yield onFormSubmit(layout);
    });
    return (React.createElement("div", { ref: dirtinessScope, style: {
            height: '70vh',
            width: '70vw',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement(SitusHeader, { title: "Layout Settings", confirmationButtonText: "Save", onConfirmationButtonClicked: isDirty ? save : undefined, rejectionButtonText: "Cancel", onRejectionButtonClicked: () => promptAndContinue(onFormCancel || EmptyFn) }),
        React.createElement(DataEntryForm, { initialData: initialData, formConfig: formView, onSubmit: onSubmit })));
};
export default ViewSetLayoutSettings;
