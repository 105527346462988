var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ErrorBoundary } from '@samc/react-ui-core';
import { MapToListViewMember, } from '@samc/screen-config-api';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import QueryExecuteManager from '../../molecules/QueryExecuteManager/QueryExecuteManager';
import { useMissingViewDependencies } from '../../hooks/useMissingViewDependencies/useMissingViewDependencies';
import { GridScreenInternal } from './GridScreenInternal';
import { usePreparedEvaluatorConfigurationByDomain } from '../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
import { ProgressIndicator } from '../../atoms/ProgressIndicator/ProgressIndicator';
const _GridScreen = (props, ref) => {
    var _a;
    const { listView, refreshListView, setListViewId, selectedFilterIds, setSelectedFilterIds, filters, onSubmit, onExcelExport, defaultData, children, showContent, style, suppressDataLoad, gridDisplayOptions, errors, setErrors, onSelectionChanges, parentEntityName, onGridApiChanged, onAddGridReady, onBodyGridReady, onChangeData, disableAddRowButton, onIdSelectionChanged, preserveInitialRecordsOnReset, initialAddedRecords, hideControlBar, autoHeight, printMode, suppressPaginationPanel, gridClassName, sizeColumnsToFit, suppressLinks, onToggleEdit, enableGroupByGrid, groupByFieldName, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const gridScreenInternalRef = React.useRef(null);
    const handleReset = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        (_b = gridScreenInternalRef.current) === null || _b === void 0 ? void 0 : _b.reset();
    }), []);
    const { missingViewMembers: missingFieldDependencies, isLoading: areMissingViewMembersLoading } = useMissingViewDependencies(listView, listView.listViewMembers, MapToListViewMember);
    const adhocListViewMembers = React.useMemo(() => missingFieldDependencies.map((listViewMember) => ({
        scalarExpression: `[${listViewMember.viewFieldName}]`,
        scalarDisplayName: `${listViewMember.viewFieldName}`,
    })), [missingFieldDependencies]);
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(listView.domainId);
    React.useImperativeHandle(ref, () => ({
        reset: handleReset,
    }), [handleReset]);
    if (areMissingViewMembersLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading Missing View Members..." });
    }
    if (!evaluatorConfig.isInitialized) {
        return React.createElement(ProgressIndicator, { label: "Loading Field Configurations..." });
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
            React.createElement(QueryExecuteManager, { domainId: listView.domainId, listViewId: (_a = listView.id) !== null && _a !== void 0 ? _a : '', filters: filters, adhocListViewMembers: adhocListViewMembers, defaultSortParameters: listView.defaultSortOrder }, (queryRenderProps) => (React.createElement(GridScreenInternal, { suppressLinks: suppressLinks, suppressPaginationPanel: suppressPaginationPanel, style: style, ref: gridScreenInternalRef, showContent: showContent, onExcelExport: onExcelExport, listView: listView, refreshListView: refreshListView, setListViewId: setListViewId, onChangeData: onChangeData, suppressDataLoad: suppressDataLoad, doFetch: queryRenderProps.doFetch, onSubmit: onSubmit, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, extraListViewMembers: missingFieldDependencies, defaultData: defaultData, gridDisplayOptions: gridDisplayOptions, errors: errors, setErrors: setErrors, filters: filters, onSelectionChanges: onSelectionChanges, parentEntityName: parentEntityName, onAddGridReady: onAddGridReady, onBodyGridReady: onBodyGridReady, onGridApiChanged: onGridApiChanged, disableAddRowButton: disableAddRowButton, onIdSelectionChanged: onIdSelectionChanged, preserveInitialRecordsOnReset: preserveInitialRecordsOnReset, initialAddedRecords: initialAddedRecords, hideControlBar: hideControlBar, autoHeight: autoHeight, printMode: printMode, gridClassName: gridClassName, sizeColumnsToFit: sizeColumnsToFit, onToggleEdit: onToggleEdit, enableGroupByGrid: enableGroupByGrid, groupByFieldName: groupByFieldName, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }, children))))));
};
/**
 * Responsible for setting up the fetch method for loading data into the grid.
 * @param props
 * @returns
 */
export const GridScreen = React.forwardRef(_GridScreen);
export default GridScreen;
