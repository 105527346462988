/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDomain, useFieldConfigurationByDomain, } from '@samc/screen-config-api';
import { Spinner } from '@fluentui/react';
import { MessageBar } from '@samc/react-ui-core';
import { EvaluatorConfigurationProvider, useEvaluator } from '@samc/expressions-react';
import { LONG_NAME_FIELD, REFERENCE_ID_FIELD } from '../Constants';
import { usePreparedEvaluatorConfigurationByDomain } from '../../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
import { mapDataSourceTypeToGridType } from '../../../organisms/GridScreen/GridScreen.utilities';
import { BaseRecordDropdown } from '../BaseRecordDropdown/BaseRecordDropdown';
import QueryExecuteManager from '../../../molecules/QueryExecuteManager/QueryExecuteManager';
/**
 * Takes any number of {@link FieldConfigurationMember} instances and maps them to {@link DomainViewMember} instances.
 */
const getDomainViewMembers = (...fcms) => fcms
    .filter((fcm) => !!fcm)
    .map(({ viewFieldName }) => ({ scalarDisplayName: viewFieldName, scalarExpression: `[${viewFieldName}]` }));
const DefaultRecordDropdownWithDomain = (props) => {
    const { domain, fieldConfigurationMembers, filters, domainId } = props;
    const { primaryKey: primaryKeyField } = domain;
    const { evaluate } = useEvaluator();
    /**
     * The field configurations indexed by lowercase string
     */
    const indexableFields = React.useMemo(() => {
        return fieldConfigurationMembers.reduce((all, cur) => {
            return Object.assign(Object.assign({}, all), { [cur.viewFieldName.toLowerCase()]: cur });
        }, {});
    }, [fieldConfigurationMembers]);
    const idField = indexableFields[primaryKeyField.toLowerCase()];
    const referenceIdField = indexableFields[REFERENCE_ID_FIELD.toLowerCase()];
    const longNameField = indexableFields[LONG_NAME_FIELD.toLowerCase()];
    const aggregatedIdField = referenceIdField !== null && referenceIdField !== void 0 ? referenceIdField : idField;
    const gridFields = React.useMemo(() => [
        ...(aggregatedIdField
            ? [
                {
                    field: aggregatedIdField.viewFieldName,
                    type: mapDataSourceTypeToGridType(aggregatedIdField),
                    headerName: String(aggregatedIdField.displayNameExpression
                        ? evaluate({}, aggregatedIdField.displayNameExpression, true)
                        : aggregatedIdField.viewFieldName),
                    width: 150,
                },
            ]
            : []),
        ...(longNameField
            ? [
                {
                    field: longNameField.viewFieldName,
                    type: mapDataSourceTypeToGridType(longNameField),
                    headerName: String(longNameField.displayNameExpression
                        ? evaluate({}, longNameField.displayNameExpression, true)
                        : longNameField.viewFieldName),
                    width: 250,
                    sort: 'asc',
                },
            ]
            : []),
    ], [aggregatedIdField, evaluate, longNameField]);
    const adhocListViewMembers = React.useMemo(() => getDomainViewMembers(idField, referenceIdField, longNameField), [idField, longNameField, referenceIdField]);
    return (React.createElement(QueryExecuteManager, { domainId: domainId, adhocListViewMembers: adhocListViewMembers, filters: filters }, (queryRenderProps) => (React.createElement(BaseRecordDropdown, Object.assign({}, props, { primaryKeyField: primaryKeyField, doFetch: queryRenderProps.doFetch, fields: gridFields, displayFieldName: LONG_NAME_FIELD })))));
};
export const DefaultRecordDropdown = (props) => {
    const { domainId } = props;
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const { data: [fieldConfiguration] = [], isLoading: isFieldConfigurationLoading } = useFieldConfigurationByDomain(domainId);
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(domainId);
    if (isDomainLoading)
        return React.createElement(Spinner, { label: "Loading domain...", labelPosition: "right" });
    if (!domain)
        return React.createElement(MessageBar, { text: "Failed to load domain" });
    if (isFieldConfigurationLoading)
        return React.createElement(Spinner, { label: "Loading field configuration...", labelPosition: "right" });
    if (!fieldConfiguration)
        return React.createElement(MessageBar, { text: "Failed to load field configuration" });
    return (React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
        React.createElement(DefaultRecordDropdownWithDomain, Object.assign({}, props, { fieldConfigurationMembers: fieldConfiguration.fieldConfigurationMembers, domain: domain }))));
};
export default DefaultRecordDropdown;
