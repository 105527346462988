var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDomain, useTaskSubmit } from '@samc/screen-config-api';
import { ConfirmationDialog, ToastMessage, toastError, LoadingModal, useDirtinessSync, toastSuccess, } from '@samc/react-ui-core';
import { TaskResponsePopup } from '../../../molecules/TaskResponsePopup/TaskResponsePopup';
import { ConfirmationModalWrapper } from '../../../molecules/ConfirmationModalWrapper/ConfirmationModalWrapper';
export const DeleteActionPopup = (props) => {
    const { singleItemName, title, onSubmit, onDismiss } = props;
    const detail = `Are you sure you want to delete this ${singleItemName}?`;
    const giveAnswer = (answer) => {
        if (answer)
            onSubmit();
        else
            onDismiss();
    };
    return React.createElement(ConfirmationDialog, { title: title, detail: detail, giveAnswer: giveAnswer });
};
const DeleteActionViewPopup = (props) => {
    const { viewId, viewType, primaryKeyValue, onDismiss, onSubmit, title, ViewModal, FormScreenWrapper, ServiceDrivenViewSet, GridScreenWrapper, } = props;
    const saveMediatorRef = React.useRef(null);
    useDirtinessSync({
        scope: saveMediatorRef,
        isDirty: true, // always dirty!
        onReset: onDismiss,
        onSave: onSubmit,
        blockChildren: true, // IMPORTANT! Otherwise, normal ViewSet/View save will occur, too
    });
    return (React.createElement(ConfirmationModalWrapper, { isOpen: true, headerText: title, onDismiss: onDismiss },
        React.createElement("div", { ref: saveMediatorRef },
            React.createElement(ViewModal.Contents, { dismissOnSave: true, onDismiss: onDismiss, primaryKeyValue: primaryKeyValue, viewId: viewId, viewType: viewType, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))));
};
/**
 * Launches a Delete popup to allow the user to delete current item
 * @param props
 * @returns
 */
export const DeleteAction = (props) => {
    var _a, _b;
    const { action, data, primaryKeyValue, onDismiss, onSuccess } = props;
    const { viewId, viewType, domainId } = action;
    const isDeleteActionViewPopup = viewId != null && viewType != null;
    const hasData = !!data;
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const title = `${domain === null || domain === void 0 ? void 0 : domain.singleItemName} Delete`;
    const [requestResponse, setRequestResponse] = React.useState();
    const getRequest = React.useMemo(() => {
        return (requestResponse === null || requestResponse === void 0 ? void 0 : requestResponse.request)
            ? requestResponse.request
            : [
                {
                    domainId: '',
                    requestIdentifier: '',
                    payload: {},
                },
            ];
    }, [requestResponse]);
    const [errorsVisible, setErrorsVisible] = React.useState(false);
    const [overrideCodes, setOverrideCodes] = React.useState([]);
    const [taskSubmit] = useTaskSubmit();
    const handleOnOverride = React.useCallback((newCodes) => {
        setOverrideCodes(newCodes);
        setErrorsVisible(false);
    }, []);
    // warnings for all subcomponents
    React.useEffect(() => {
        if (!primaryKeyValue) {
            toastError(React.createElement(ToastMessage, { title: "Missing Primary Key", message: "Primary Key value is missing from this Action but is required for a Delete Action" }));
            onDismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryKeyValue]);
    // warnings for DeleteActionPopup
    React.useEffect(() => {
        if (isDeleteActionViewPopup)
            return;
        if (!hasData) {
            toastError(React.createElement(ToastMessage, { title: "Missing Data", message: "Data is missing from this Action but is required for a Delete Action" }));
            onDismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteActionViewPopup, hasData]);
    // handle missing domain
    const isDomainMissing = !action.domainId || (!isDomainLoading && !domain);
    React.useEffect(() => {
        if (isDomainMissing) {
            toastError(React.createElement(ToastMessage, { title: "Missing Domain", message: "Domain data is missing from this Action but is required for a Delete Action" }));
            onDismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDomainMissing]);
    if (isDomainLoading)
        return React.createElement(LoadingModal, { text: "Loading Domain..." });
    if (!domain || !primaryKeyValue)
        return null;
    const handleSubmit = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (requests = []) {
        let request;
        if (requests && requests.length > 0) {
            [request] = requests;
        }
        else {
            request = {
                domainId: domain.id,
                fieldConfigurationId: action.fieldConfigurationId || domain.id,
                payload: {
                    [String(primaryKeyValue)]: Object.assign(Object.assign({}, data), { [domain.primaryKey]: primaryKeyValue, __DELETED__: true }),
                },
                requestIdentifier: 'DeleteAction',
                overrideCodes,
            };
        }
        const [result] = yield taskSubmit(request);
        if (result.messages && result.messages.length > 0) {
            setErrorsVisible(true);
            setRequestResponse({ request: [request], response: [result] });
        }
        else if (result.prompts && result.prompts.length > 0) {
            setErrorsVisible(true);
            setRequestResponse({ request: [request], response: [result] });
        }
        else {
            toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Deleted Successfully!!!" }), {
                autoClose: 3000,
            });
            onDismiss();
            if (onSuccess)
                onSuccess();
        }
    });
    return (React.createElement(React.Fragment, null,
        isDeleteActionViewPopup && (React.createElement(DeleteActionViewPopup, Object.assign({}, props, { title: title, onDismiss: onDismiss, onSubmit: handleSubmit, primaryKeyValue: primaryKeyValue, viewId: viewId, viewType: viewType }))),
        !isDeleteActionViewPopup && hasData && (React.createElement(DeleteActionPopup, { singleItemName: (_a = domain.singleItemName) !== null && _a !== void 0 ? _a : 'Record', title: title, onDismiss: onDismiss, onSubmit: handleSubmit })),
        React.createElement(TaskResponsePopup, { responses: (_b = requestResponse === null || requestResponse === void 0 ? void 0 : requestResponse.response) !== null && _b !== void 0 ? _b : [], requests: getRequest, visible: errorsVisible, setVisible: setErrorsVisible, submitWithUpdates: handleSubmit, submitWithOverrides: handleOnOverride })));
};
export default DeleteAction;
