import React from 'react';
import { InputType, LayoutType, useFormApi } from '@samc/react-ui-form';
import { InputType as APIInputType, } from '@samc/screen-config-api';
import { CalculationFrequency } from '@samc/screen-config-api/lib/model/CalculationFrequency';
import { FetchItems } from '../../../hooks/useConfigureFieldsMeta/fetchItems';
import { InputTypeFieldConfig } from '../../specialized/InputTypeFieldConfig/InputTypeFieldConfig';
import { getExpressionFormField } from '../../../atoms/ExpressionField/ExpressionField';
import { getCalculationRuleField } from '../../../atoms/CalculationRuleField/CalculationRuleField';
import { getDomainPickerFormField } from '../../../molecules/controls/DomainPicker/DomainPicker';
import { getLegacyMultiSelectEntryFieldRenderer } from '../../../atoms/LegacyMultiSelectEntryFieldRenderer/LegacyMultiSelectEntryFieldRenderer';
const editableExpression = '[isLocked] = false';
export const useFieldConfigurationForm = (params) => {
    const { domain, fieldConfiguration } = params;
    const { id: domainId, primaryKey: primaryKeyField } = domain;
    const [api, onFormLoaded] = useFormApi();
    const transformedForm = React.useMemo(() => ({
        title: 'Screen Config',
        fields: {
            viewFieldName: {
                displayNameRule: "'Field'",
                field: 'viewFieldName',
                editableRule: 'false',
                inputType: InputType.Text,
            },
            isLocked: {
                displayNameRule: "'Is Locked'",
                field: 'isLocked',
                editableRule: 'false',
                inputType: InputType.Checkbox,
            },
            requiredExpression: {
                displayNameRule: "'Required Rule'",
                field: 'requiredExpression',
                editableRule: editableExpression,
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'requiredExpression'),
            },
            displayNameExpression: {
                displayNameRule: "'Display Name Rule'",
                field: 'displayNameExpression',
                editableRule: 'true',
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'displayNameExpression'),
            },
            editableExpression: {
                displayNameRule: "'Editable Rule'",
                field: 'editableExpression',
                editableRule: editableExpression,
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'editableExpression'),
            },
            visibleExpression: {
                displayNameRule: "'Visible Rule'",
                field: 'visibleExpression',
                editableRule: 'true',
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'visibleExpression'),
            },
            defaultValueExpression: {
                displayNameRule: "'Default Value Rule'",
                field: 'defaultValueExpression',
                editableRule: editableExpression,
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'defaultValueExpression'),
            },
            hexFillRule: {
                displayNameRule: "'Fill Rule'",
                field: 'hexFillRule',
                editableRule: 'true',
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'hexFillRule'),
            },
            isKeyField: {
                displayNameRule: "'Is Key Field'",
                field: 'isKeyField',
                editableRule: editableExpression,
                inputType: InputType.Checkbox,
            },
            formatId: {
                displayNameRule: "'Format'",
                field: 'formatId',
                editableRule: `([inputType] <> ${APIInputType.Date}) && ([inputType] <> ${APIInputType.DateTime}) && ([inputType] <> ${APIInputType.Time})`,
                inputType: InputType.Text,
            },
            inputType: InputTypeFieldConfig(domain),
            allowedFileTypes: {
                displayNameRule: "'Allowed File Types'",
                field: 'allowedFileTypes',
                editableRule: 'true',
                visibleRule: `([inputType] = ${APIInputType.StrataFile})`,
                inputType: InputType.Text,
            },
            domainId: {
                displayNameRule: "'Domain'",
                requiredFieldRule: `([inputType] = ${APIInputType.ListView}) OR ([inputType] = ${APIInputType.FormView}) OR ([inputType] = ${APIInputType.Expression})`,
                field: 'domainId',
                editableRule: `([inputType] = ${APIInputType.ListView}) OR ([inputType] = ${APIInputType.FormView}) OR ([inputType] = ${APIInputType.Expression})`,
                visibleRule: `([inputType] = ${APIInputType.ListView}) OR ([inputType] = ${APIInputType.FormView}) OR ([inputType] = ${APIInputType.Expression})`,
                onRenderField: getDomainPickerFormField(),
                inputType: InputType.Select,
            },
            'unitOfMeasureProperties.units': {
                displayNameRule: "'Units'",
                field: 'unitOfMeasureProperties.units',
                editableRule: FetchItems.fetchFieldExpression('editable', true, true, APIInputType.UnitOfMeasure, APIInputType.CurrencyPerArea),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, true, APIInputType.UnitOfMeasure, APIInputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, true, APIInputType.UnitOfMeasure, APIInputType.CurrencyPerArea),
                inputType: InputType.MultiSelect,
                menuItems: FetchItems.fetchItems('id', 'unitOfMeasure'),
                onRenderField: getLegacyMultiSelectEntryFieldRenderer(),
                valueProperty: 'id',
                displayProperty: 'unitOfMeasure',
            },
            'measurementProperties.unitOfMeasureFieldName': {
                displayNameRule: "'Unit Of Measure Field'",
                field: 'measurementProperties.unitOfMeasureFieldName',
                editableRule: FetchItems.fetchFieldExpression('editable', true, undefined, InputType.MeasurementField, InputType.CurrencyPerArea),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, undefined, InputType.MeasurementField, InputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, undefined, InputType.MeasurementField, InputType.CurrencyPerArea),
                inputType: InputType.Select,
                valueProperty: 'field',
                displayProperty: 'fieldName',
                menuItems: FetchItems.fetchFields(APIInputType.UnitOfMeasure, 'field', 'fieldName', fieldConfiguration),
            },
            'measurementProperties.allowUnitOfMeasureChange': {
                displayNameRule: "'Allow Unit Change'",
                field: 'measurementProperties.allowUnitOfMeasureChange',
                editableRule: FetchItems.fetchFieldExpression('editable', true, undefined, InputType.MeasurementField),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, undefined, InputType.MeasurementField),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, undefined, InputType.MeasurementField),
                inputType: InputType.Checkbox,
            },
            'unitOfCurrencyProperties.units': {
                displayNameRule: "'Units'",
                field: 'unitOfCurrencyProperties.units',
                editableRule: FetchItems.fetchFieldExpression('editable', true, true, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, true, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, true, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                inputType: InputType.MultiSelect,
                menuItems: FetchItems.fetchMonetaryItems('id', 'unitOfCurrency'),
                onRenderField: getLegacyMultiSelectEntryFieldRenderer(),
                valueProperty: 'id',
                displayProperty: 'unitOfCurrency',
            },
            'currencyProperties.unitOfCurrencyFieldName': {
                displayNameRule: "'Unit Of Currency Field'",
                field: 'currencyProperties.unitOfCurrencyFieldName',
                editableRule: FetchItems.fetchFieldExpression('editable', true, undefined, InputType.CurrencyField, InputType.CurrencyPerArea),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, undefined, InputType.CurrencyField, InputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, undefined, InputType.CurrencyField, InputType.CurrencyPerArea),
                inputType: InputType.Select,
                valueProperty: 'field',
                displayProperty: 'fieldName',
                menuItems: FetchItems.fetchFields(APIInputType.UnitOfCurrency, 'field', 'fieldName', fieldConfiguration),
            },
            'currencyProperties.allowUnitOfCurrencyChange': {
                displayNameRule: "'Allow Unit Change'",
                field: 'currencyProperties.allowUnitOfCurrencyChange',
                editableRule: FetchItems.fetchFieldExpression('editable', true, undefined, InputType.CurrencyField),
                requiredFieldRule: FetchItems.fetchFieldExpression('required', undefined, undefined, InputType.CurrencyField),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, undefined, InputType.CurrencyField),
                inputType: InputType.Checkbox,
            },
            'confirmOptions.title': {
                displayNameRule: "'Confirm Title'",
                field: 'confirmOptions.title',
                editableRule: FetchItems.fetchFieldExpression('editable', true, true, APIInputType.UnitOfMeasure, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, true, APIInputType.UnitOfMeasure, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                inputType: InputType.Text,
            },
            'confirmOptions.detail': {
                displayNameRule: "'Confirm Message'",
                field: 'confirmOptions.detail',
                editableRule: FetchItems.fetchFieldExpression('editable', true, true, APIInputType.UnitOfMeasure, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                visibleRule: FetchItems.fetchFieldExpression('visible', undefined, true, APIInputType.UnitOfMeasure, APIInputType.UnitOfCurrency, APIInputType.CurrencyPerArea),
                inputType: InputType.MultilineText,
            },
            tooltip: {
                displayNameRule: "'Tooltip'",
                field: 'tooltip',
                editableRule: 'true',
                inputType: InputType.MultilineText,
            },
            horizontalAlignment: {
                displayNameRule: "'Horizontal Alignment'",
                field: 'horizontalAlignment',
                editableRule: editableExpression,
                inputType: InputType.Select,
                valueProperty: 'id',
                displayProperty: 'type',
                menuItems: FetchItems.fetchHorizontalAlignment('id', 'type'),
            },
            calculationFrequency: {
                displayNameRule: "'Calculation Frequency'",
                field: 'calculationFrequency',
                editableRule: editableExpression,
                inputType: InputType.Select,
                valueProperty: 'id',
                displayProperty: 'type',
                menuItems: FetchItems.fetchCalculationFrequency('id', 'type'),
            },
            calculationRule: {
                displayNameRule: "'Calculation Rule'",
                visibleRule: `IIF([calculationFrequency] = ${CalculationFrequency.Never}, false, true)`,
                field: 'calculationRule',
                editableRule: editableExpression,
                inputType: InputType.MultilineExpression,
                onRenderField: getCalculationRuleField(domainId, primaryKeyField || 'Id', () => api === null || api === void 0 ? void 0 : api.submitForm()),
            },
            calculationCondition: {
                displayNameRule: "'Calculation Condition'",
                field: 'calculationCondition',
                visibleRule: `IIF([calculationFrequency] = ${CalculationFrequency.Conditionally}, true, false)`,
                editableRule: editableExpression,
                inputType: InputType.MultilineExpression,
                onRenderField: getExpressionFormField(domainId, 'calculationCondition'),
            },
        },
        gridFields: [],
        tabs: [
            {
                title: '',
                sections: [
                    {
                        titleExpression: "'Field Configurations'",
                        fieldLayout: {
                            type: LayoutType.VerticalStack,
                            children: [
                                { field: 'viewFieldName', type: LayoutType.VerticalStack, children: [] },
                                { field: 'isLocked', type: LayoutType.VerticalStack, children: [] },
                                { field: 'requiredExpression', type: LayoutType.VerticalStack, children: [] },
                                { field: 'displayNameExpression', type: LayoutType.VerticalStack, children: [] },
                                { field: 'editableExpression', type: LayoutType.VerticalStack, children: [] },
                                { field: 'visibleExpression', type: LayoutType.VerticalStack, children: [] },
                                { field: 'defaultValueExpression', type: LayoutType.VerticalStack, children: [] },
                                { field: 'hexFillRule', type: LayoutType.VerticalStack, children: [] },
                                { field: 'isKeyField', type: LayoutType.VerticalStack, children: [] },
                                { field: 'formatId', type: LayoutType.VerticalStack, children: [] },
                                { field: 'inputType', type: LayoutType.VerticalStack, children: [] },
                                { field: 'allowedFileTypes', type: LayoutType.VerticalStack, children: [] },
                                { field: 'domainId', type: LayoutType.VerticalStack, children: [] },
                                {
                                    field: 'unitOfMeasureProperties.units',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'measurementProperties.unitOfMeasureFieldName',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'measurementProperties.allowUnitOfMeasureChange',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'unitOfCurrencyProperties.units',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'currencyProperties.unitOfCurrencyFieldName',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'currencyProperties.allowUnitOfCurrencyChange',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                { field: 'confirmOptions.title', type: LayoutType.VerticalStack, children: [] },
                                { field: 'confirmOptions.detail', type: LayoutType.VerticalStack, children: [] },
                                { field: 'tooltip', type: LayoutType.VerticalStack, children: [] },
                                { field: 'horizontalAlignment', type: LayoutType.VerticalStack, children: [] },
                                { field: 'calculationFrequency', type: LayoutType.VerticalStack, children: [] },
                                { field: 'calculationCondition', type: LayoutType.VerticalStack, children: [] },
                                { field: 'calculationRule', type: LayoutType.VerticalStack, children: [] },
                            ],
                        },
                    },
                ],
                layout: {
                    type: LayoutType.HorizontalStack,
                    children: [{ index: 0, type: LayoutType.VerticalStack, children: [] }],
                },
            },
        ],
    }), [api, domain, domainId, fieldConfiguration, primaryKeyField]);
    return { form: transformedForm, onFormLoaded };
};
export default useFieldConfigurationForm;
