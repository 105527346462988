var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@samc/react-ui-grid/lib/styles/ag-theme-react-ui-library.css';
import { ToolbarButton, isNullOrUndefined } from '@samc/react-ui-core';
import { Modal } from '@fluentui/react';
import { useViewSetSaver, ViewType } from '@samc/screen-config-api';
import { ViewSetTabSettings } from './ViewSetTabSettings/ViewSetTabSettings';
import { ViewSetLayoutSettings } from './ViewSetLayoutSettings/ViewSetLayoutSettings';
import { generateId, mapViewSetTab, tabIdAlreadyExists, getSelectedViewSet, getSelectedTab, } from '../ConfigureViewSetHelper';
import { FormViewModal } from './FormViewModal/FormViewModal';
import { ConfigureListViewEditor } from './ConfigureListView/ConfigureListViewEditor';
const ROW_HEIGHT = 35;
const DefaultContextMenuItems = ['copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste'];
export const ConfigureViewSetRenderer = (props) => {
    const { viewSets, fieldConfigurationId, forceUpdate, rowData: inputRowData, domainId, domainFields, childGrids, } = props;
    const [rowData, _setRowData] = React.useState(inputRowData ? [...inputRowData] : []);
    const [listViewId, setListViewId] = React.useState();
    const [formViewId, setFormViewId] = React.useState();
    const [tabEdit, setTabEdit] = React.useState();
    const [layoutEdit, setLayoutEdit] = React.useState();
    const [tabReorder, setTabReorder] = React.useState();
    const [saveViewSet] = useViewSetSaver({});
    const columnDefs = React.useMemo(() => [
        {
            field: 'type',
            width: 100,
            valueGetter: ({ data }) => {
                if (!data)
                    return null;
                const { type } = data;
                if (type !== 'Layout' || isNullOrUndefined(data.layoutType))
                    return type;
                switch (data.layoutType) {
                    case ViewType.ContactAssignmentGrid:
                    case ViewType.Form:
                        return 'Form Layout';
                    case ViewType.Grid:
                        return 'Grid Layout';
                    default:
                        return type;
                }
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const addRenderer = {
                    component: 'addButtonRenderer',
                };
                if (params.data.type === 'Tab')
                    return addRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const editRenderer = {
                    component: 'editButtonRenderer',
                };
                if (params.data.type === 'Tab')
                    return editRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const editRenderer = {
                    component: 'editButtonRenderer',
                };
                const deleteRenderer = {
                    component: 'deleteButtonRenderer',
                };
                if (params.data.type === 'Layout')
                    return editRenderer;
                if (params.data.type === 'Tab')
                    return deleteRenderer;
                return {};
            },
        },
        {
            width: 50,
            cellRendererSelector: (params) => {
                const cloneRenderer = {
                    component: 'cloneButtonRenderer',
                };
                const addRenderer = {
                    component: 'addButtonRenderer',
                };
                const deleteRenderer = {
                    component: 'deleteButtonRenderer',
                };
                if (params.data.type === 'Screen')
                    return addRenderer;
                if (params.data.type === 'Tab')
                    return cloneRenderer;
                return deleteRenderer;
            },
        },
    ], []);
    const valueFormatter = (params) => {
        const { data } = params;
        if (!data)
            return '';
        const { item } = data;
        return item.at(-1) || 'Item';
    };
    const [defaultColumnDef] = React.useState({ resizable: true, suppressMovable: true, suppressMenu: true });
    const [autoGroupColumnDef] = React.useState({
        headerName: 'Item',
        flex: 2,
        cellRendererParams: { suppressCount: true },
        valueFormatter,
        rowDrag: (params) => {
            const { data } = params.node;
            if (!data)
                return false;
            const { type } = data;
            return type === 'Tab';
        },
    });
    const [groupDefaultExpanded] = React.useState(-1);
    const getDataPath = (data) => {
        const updatedData = JSON.parse(JSON.stringify(data));
        updatedData.item.forEach((_, i) => {
            if (i === 0)
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.viewSetId}`;
            else if (i === 1)
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.tabId}`;
            else
                updatedData.item[i] = `${updatedData.item[i]}$${updatedData.viewId}`;
        });
        return updatedData.item;
    };
    const AddButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, type } = data;
        const handleAddTabClick = () => {
            setTabEdit({
                viewSetId,
                data: {
                    id: generateId(),
                    visibleExpression: 'true',
                    controlVisibilities: {
                        wrapText: false,
                    },
                    layouts: [],
                    defaultLayout: {},
                    baselineFilterExpression: '',
                    title: '',
                    layoutSelectionExpression: '',
                    titleExpression: '',
                },
            });
        };
        const handleAddLayoutClick = () => {
            if (!domainId || !domainFields)
                return;
            setLayoutEdit({ tabId, viewSetId, data: {} });
        };
        if (type === 'Screen')
            return (React.createElement(ToolbarButton, { onClick: handleAddTabClick, iconName: "CircleAddition", tooltip: "Add a new tab", iconStyle: { backgroundColor: 'transparent' } }));
        return (React.createElement(ToolbarButton, { onClick: handleAddLayoutClick, iconName: "CircleAddition", tooltip: "Add a new Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [domainFields, domainId]);
    const EditButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        if (!data || !viewSets)
            return React.createElement("div", null, "No data!");
        const { viewSetId, type } = data;
        const handleEditTabClick = () => {
            if (type !== 'Tab')
                return;
            const { tabId } = data;
            const viewSet = viewSets.find((x) => x.id === viewSetId);
            if (!viewSet)
                return;
            const selectedTab = viewSet.tabs.find((x) => x.id === tabId);
            if (!selectedTab)
                return;
            setTabEdit({ viewSetId, tabId, data: selectedTab });
        };
        const handleEditLayoutClick = () => {
            if (type !== 'Layout')
                return;
            const { tabId, layoutIndex } = data;
            const viewSet = viewSets.find((x) => x.id === viewSetId);
            if (!viewSet)
                return;
            const selectedTab = viewSet.tabs.find((x) => x.id === tabId);
            if (!selectedTab)
                return;
            const layout = selectedTab.layouts[layoutIndex];
            if (!layout)
                return;
            setLayoutEdit({
                viewSetId,
                tabId,
                layoutIndex,
                data: layout,
            });
        };
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleEditTabClick, iconName: "Edit", tooltip: "Edit Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return (React.createElement(ToolbarButton, { onClick: handleEditLayoutClick, iconName: "Edit", tooltip: "Edit Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [viewSets]);
    const DeleteButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        if (!data || !viewSets)
            return React.createElement("div", null, "No data");
        const { viewSetId, type } = data;
        const handleDeleteTabClick = () => __awaiter(void 0, void 0, void 0, function* () {
            if (type !== 'Tab')
                return;
            const { tabId } = data;
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            if (!selectedViewSet)
                return;
            const tabIndex = selectedViewSet.tabs.findIndex((x) => x.id === tabId);
            if (tabIndex === -1)
                return;
            selectedViewSet.tabs.splice(tabIndex, 1);
            yield saveViewSet(selectedViewSet);
            forceUpdate();
        });
        const handleDeleteLayoutClick = () => __awaiter(void 0, void 0, void 0, function* () {
            if (type !== 'Layout')
                return;
            const { tabId, layoutIndex } = data;
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            if (!selectedViewSet)
                return;
            const selectedTab = selectedViewSet.tabs.find((x) => x.id === tabId);
            if (!selectedTab)
                return;
            selectedTab.layouts = selectedTab.layouts.splice(layoutIndex, 1);
            yield saveViewSet(selectedViewSet);
            forceUpdate();
        });
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleDeleteTabClick, iconName: "Delete", tooltip: "Delete Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return (React.createElement(ToolbarButton, { onClick: handleDeleteLayoutClick, iconName: "Delete", tooltip: "Delete Layout", iconStyle: { backgroundColor: 'transparent' } }));
    }, [forceUpdate, saveViewSet, viewSets]);
    const CloneButtonRenderer = React.useCallback((p) => {
        const { data } = p;
        const { viewSetId, tabId, type } = data;
        const handleCloneTabClick = () => {
            const selectedViewSet = viewSets.filter((x) => x.id === viewSetId)[0];
            const selectedTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            // Only change the Id and the title
            const clonedTab = Object.assign(Object.assign({}, selectedTab), { id: generateId(), title: `${selectedTab.title}_clone` });
            setTabEdit({ viewSetId: selectedViewSet.id, data: clonedTab });
        };
        if (type === 'Tab') {
            return (React.createElement(ToolbarButton, { onClick: handleCloneTabClick, iconName: "DuplicateRow", tooltip: "Clone Tab", iconStyle: { backgroundColor: 'transparent' } }));
        }
        return React.createElement(React.Fragment, null);
    }, [viewSets]);
    const [frameworkComponents] = React.useState({
        addButtonRenderer: AddButtonRenderer,
        editButtonRenderer: EditButtonRenderer,
        deleteButtonRenderer: DeleteButtonRenderer,
        cloneButtonRenderer: CloneButtonRenderer,
    });
    const getRowHeight = () => {
        return ROW_HEIGHT;
    };
    const handleTabEditorSubmit = (viewSetTab) => __awaiter(void 0, void 0, void 0, function* () {
        if (!tabEdit || !viewSets)
            return;
        const { viewSetId, tabId, data } = tabEdit;
        const selectedViewSet = getSelectedViewSet(viewSets, viewSetId);
        if (!tabId || !tabIdAlreadyExists(selectedViewSet.tabs, tabId)) {
            selectedViewSet.tabs.push(Object.assign(Object.assign({}, viewSetTab), { layouts: data.layouts ? data.layouts : [] }));
        }
        else {
            const existingTab = selectedViewSet.tabs.filter((x) => x.id === tabId)[0];
            mapViewSetTab(viewSetTab, existingTab);
        }
        yield saveViewSet(selectedViewSet);
        setTabEdit(undefined);
        forceUpdate();
    });
    const handleTabEditorCancel = () => setTabEdit(undefined);
    const handleLayoutEditorSubmit = (layout) => __awaiter(void 0, void 0, void 0, function* () {
        if (!viewSets || !layoutEdit)
            return;
        const { viewSetId, tabId, layoutIndex } = layoutEdit;
        const selectedViewSet = getSelectedViewSet(viewSets, viewSetId);
        const selectedTab = getSelectedTab(selectedViewSet, tabId);
        if (layoutIndex != null)
            selectedTab.layouts[layoutIndex] = layout;
        else
            selectedTab.layouts.push(layout);
        const numLayouts = selectedTab.layouts.length;
        // If this is the only layout in the tab, then set it as default
        if (numLayouts === 1)
            [selectedTab.defaultLayout] = selectedTab.layouts;
        yield saveViewSet(selectedViewSet);
        setLayoutEdit(undefined);
        forceUpdate();
    });
    const handleLayoutEditorCancel = () => setLayoutEdit(undefined);
    const handleFormSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!domainId)
            return;
        setFormViewId(undefined);
        forceUpdate();
    });
    const handleEditListViewClose = () => setListViewId(undefined);
    const handleOnListViewSave = () => __awaiter(void 0, void 0, void 0, function* () {
        setListViewId(undefined);
        forceUpdate();
    });
    const handleOnRowDragEnter = (event) => {
        const { node } = event;
        const { data } = node;
        if (!data)
            return;
        const { type, viewSetId } = data;
        if (type === 'Tab') {
            const { tabId } = data;
            setTabReorder({
                viewSetId,
                tabId,
            });
        }
    };
    const handleRowDragMove = (event) => {
        if (!tabReorder)
            return;
        const { viewSetId: currentViewSetId } = tabReorder;
        const { overNode, node } = event;
        if (!overNode)
            return;
        const { id: overId } = overNode;
        const { id } = node;
        _setRowData((curRowData) => {
            const draggedIndex = curRowData.findIndex((x) => x.id === id);
            const overIndex = curRowData.findIndex((x) => x.id === overId);
            let currentRowData;
            let currentIndex = overIndex;
            // find the nearest viewset node above overIndex
            while (!currentRowData && currentIndex >= 0) {
                const targetData = curRowData[currentIndex];
                if (targetData) {
                    const { type, viewSetId } = targetData;
                    if (type === 'Tab' && viewSetId === currentViewSetId) {
                        currentRowData = targetData;
                        currentIndex += 1;
                    }
                }
                currentIndex -= 1;
            }
            // now, reorder rowData to slip the dragged tab below the target node (currentIndex + 1)
            const draggedTab = curRowData.at(draggedIndex);
            if (!draggedTab)
                return curRowData;
            const newRowData = [...curRowData];
            newRowData.splice(draggedIndex, 1);
            newRowData.splice(currentIndex, 0, draggedTab);
            return newRowData;
        });
    };
    const handleOnRowDragEnd = () => {
        if (!tabReorder || !viewSets)
            return;
        const { viewSetId } = tabReorder;
        const selectedViewSetIndex = viewSets.findIndex((x) => x.id === viewSetId);
        const selectedViewSet = viewSets[selectedViewSetIndex];
        const _map = new Map();
        selectedViewSet.tabs.map((x) => _map.set(x.id, x));
        if (!selectedViewSet)
            return;
        const newTabs = rowData
            .filter((x) => x.viewSetId === viewSetId && x.type === 'Tab')
            .map(({ tabId }) => _map.get(tabId));
        const updatedViewSet = Object.assign(Object.assign({}, selectedViewSet), { tabs: newTabs });
        saveViewSet(updatedViewSet);
    };
    const className = `ag-theme-balham ag-theme-react-ui-library row-spacing-normal`;
    const getContextMenuItems = ({ node }) => {
        if (!node || !node.data)
            return DefaultContextMenuItems;
        const { data, parent } = node;
        switch (data.type) {
            case 'Tab': {
                if (!data.tabId || !parent || !parent.data || !parent.data.viewSetId)
                    break;
                return [
                    ...DefaultContextMenuItems,
                    {
                        name: 'Copy Tab Id',
                        action: () => navigator.clipboard.writeText(data.tabId),
                    },
                ];
            }
            default:
                break;
        }
        return DefaultContextMenuItems;
    };
    // update data when props change
    React.useEffect(() => {
        _setRowData(inputRowData || []);
    }, [inputRowData]);
    return (React.createElement("div", { style: { height: '100%' }, className: className },
        React.createElement(AgGridReact, { getRowId: ({ data }) => data.id, rowData: rowData, columnDefs: columnDefs, defaultColDef: defaultColumnDef, autoGroupColumnDef: autoGroupColumnDef, treeData: true, animateRows: true, groupDefaultExpanded: groupDefaultExpanded, getDataPath: getDataPath, rowDragManaged: false, components: frameworkComponents, getRowHeight: getRowHeight, onRowDragEnter: handleOnRowDragEnter, onRowDragEnd: handleOnRowDragEnd, onRowDragMove: handleRowDragMove, getContextMenuItems: getContextMenuItems }),
        tabEdit && (React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: () => setTabEdit(undefined), isOpen: true, layerProps: { eventBubblingEnabled: true } },
            React.createElement(ViewSetTabSettings, { domainId: domainId, tabSettingsData: tabEdit.data, onFormSubmit: handleTabEditorSubmit, onFormCancel: handleTabEditorCancel }))),
        domainId && (React.createElement(React.Fragment, null,
            layoutEdit && (React.createElement(Modal, { forceFocusInsideTrap: false, onDismiss: () => setLayoutEdit(undefined), isOpen: true, layerProps: { eventBubblingEnabled: true } },
                React.createElement(ViewSetLayoutSettings, { domainId: domainId, onFormSubmit: handleLayoutEditorSubmit, onFormCancel: handleLayoutEditorCancel, initialData: layoutEdit.data, onEditFormView: setFormViewId, onEditListView: setListViewId }))),
            fieldConfigurationId && formViewId && (React.createElement(FormViewModal, { domainFields: domainFields, formViewId: formViewId, isOpen: formViewId !== undefined, onDismiss: () => setFormViewId(undefined), onSaveForm: handleFormSubmit, domainId: domainId, fieldConfigurationId: fieldConfigurationId, childGrids: childGrids })))),
        React.createElement(ConfigureListViewEditor, { isOpen: !!listViewId, onClose: handleEditListViewClose, listViewId: listViewId, onSave: handleOnListViewSave })));
};
export default ConfigureViewSetRenderer;
