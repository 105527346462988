var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { useTaskSubmit } from '@samc/screen-config-api';
import { GridScreen } from '../GridScreen/GridScreen';
import { GetListViewManager, } from '../../molecules/GetListViewManager/GetListViewManager';
import { GetDomainManager } from '../../molecules/GetDomainManager/GetDomainManager';
import { QueryBuilderContextProvider } from '../ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
/**
 * Responsible for getting Domain/ListView metadata from API needed to render a GridScreen
 * @param props
 * @returns
 */
// eslint-disable-next-line react/display-name
export const GridScreenWrapperInner = React.forwardRef((props, ref) => {
    const { listViewId, onSubmit, filters, setListViewId, selectedFilterIds, setSelectedFilterIds, defaultData, children, showContent, style, suppressDataLoad, gridDisplayOptions, errors, setErrors, onSelectionChanges, parentEntityName, onAddGridReady, onBodyGridReady, onGridApiChanged, onChangeData, disableAddRowButton, onListViewLoadFailed, initialAddedRecords, preserveInitialRecordsOnReset, autoHeight, printMode, hideControlBar, suppressPaginationPanel, gridClassName, sizeColumnsToFit, suppressLinks, onToggleEdit, enableGroupByGrid, groupByFieldName, ServiceDrivenViewSet, GridScreenWrapper, } = props;
    const [taskSubmit] = useTaskSubmit();
    const listViewFetcherRef = React.useRef(null);
    const handleSubmit = React.useCallback((listView, request) => __awaiter(void 0, void 0, void 0, function* () {
        const [result] = yield taskSubmit(request);
        if (onSubmit) {
            onSubmit(listView, request.payload);
        }
        return result;
    }), [onSubmit, taskSubmit]);
    const refreshListView = React.useCallback(() => {
        var _a;
        (_a = listViewFetcherRef.current) === null || _a === void 0 ? void 0 : _a.reload();
    }, []);
    return (React.createElement(GetListViewManager, { ref: listViewFetcherRef, onLoadFailed: onListViewLoadFailed, listViewId: listViewId, setSelectedFilterIds: setSelectedFilterIds }, ({ listView }) => (React.createElement(GetDomainManager, { domainId: listView.domainId }, ({ domain }) => (React.createElement(QueryBuilderContextProvider, { domainId: domain.sequentialId },
        React.createElement(GridScreen, { style: style, ref: ref, onChangeData: onChangeData, showContent: showContent, listView: listView, setListViewId: setListViewId, onSubmit: handleSubmit, filters: filters, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, refreshListView: refreshListView, defaultData: defaultData, suppressDataLoad: suppressDataLoad, gridDisplayOptions: gridDisplayOptions, errors: errors, setErrors: setErrors, onSelectionChanges: onSelectionChanges, parentEntityName: parentEntityName, onGridApiChanged: onGridApiChanged, onAddGridReady: onAddGridReady, onBodyGridReady: onBodyGridReady, disableAddRowButton: disableAddRowButton, initialAddedRecords: initialAddedRecords, preserveInitialRecordsOnReset: preserveInitialRecordsOnReset, autoHeight: autoHeight, printMode: printMode, hideControlBar: hideControlBar, suppressPaginationPanel: suppressPaginationPanel, gridClassName: gridClassName, sizeColumnsToFit: sizeColumnsToFit, suppressLinks: suppressLinks, onToggleEdit: onToggleEdit, enableGroupByGrid: enableGroupByGrid, groupByFieldName: groupByFieldName, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }, children)))))));
});
