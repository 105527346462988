import React from 'react';
import { InputType } from '@samc/react-ui-form';
import { InputTypeField } from './InputTypeField';
export const InputTypeFieldConfig = (domain) => {
    return {
        displayNameRule: "'Type'",
        field: 'inputType',
        editableRule: '[isLocked] = false',
        valueProperty: 'id',
        inputType: InputType.Select,
        onRenderField: (props) => React.createElement(InputTypeField, Object.assign(Object.assign({}, props), { domain })),
    };
};
export default InputTypeFieldConfig;
