var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ViewType } from '@samc/screen-config-api';
import { useHeaderContext } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { GetValueFromData, MessageBar, MessageBarType } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { ControlBarActions } from '../../actions/ControlBarActions/ControlBarActions';
import { RecordDropdown } from '../../../atoms/RecordDropdown/RecordDropdown';
import { filterActions } from '../ViewSet.Utilities';
import { MergeExpressionData } from '../../../utils/MergeExpressionData/MergeExpressionData';
import { TabOverrideContextProvider, useTabOverrides } from '../../../contexts/TabOverrideContext/TabOverrideContext';
import { usePreparedEvaluatorConfigurationByDomain } from '../../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
import { useSelectedRowsByGridContext } from '../../../contexts/GridSelectionContext/GridSelectionContext';
import { ViewSet } from '../ViewSet';
import { useEntitledViewSet } from '../../../hooks/useEntitledViewSet/useEntitledViewSet';
import { ViewModal } from '../../modals/ViewModal/ViewModal';
import { GridScreenWrapper } from '../../GridScreenWrapper/GridScreenWrapper';
import { FormScreenWrapper } from '../../FormScreenWrapper/FormScreenWrapper';
/**
 * Takes a ViewSet and data to then render a ViewSet, the RecordPicker and any actions for the ViewSet
 * - this component can be either controlled (by the parent) or uncontrolled (it controls itself)
 * - if using it controlled, be sure to pass in both the primaryKeyValue and setPrimaryKeyValue props
 * @param props
 */
export const ViewSetWithHeader = (props) => {
    var _a;
    const { viewSet: rawViewSet, data, filters, primaryKeyValue, setPrimaryKeyValue, defaultData, baselineFilterExpression, onSelectionChanges, precedingControlRenderer, initialSelectedTabId, onTabChanged, parentScope, useFormView, submitAllAttributes, onSubtabChanged, initialSelectedSubtabId, ServiceDrivenViewSet, afterDelete, } = props;
    const [css] = useStyletron();
    const domainId = rawViewSet === null || rawViewSet === void 0 ? void 0 : rawViewSet.domainId;
    const viewSetId = rawViewSet === null || rawViewSet === void 0 ? void 0 : rawViewSet.id;
    const viewSetName = rawViewSet === null || rawViewSet === void 0 ? void 0 : rawViewSet.name;
    const { setValue: setHeaderValue, selectedTabId } = useHeaderContext();
    const [currentLayout, setCurrentLayout] = React.useState(undefined);
    const [tabExpressionMap, setTabExpressionMap] = React.useState({});
    const { selectedRowsByGrids } = useSelectedRowsByGridContext();
    const scope = parentScope ? `${parentScope}_${domainId}_${viewSetId}` : undefined;
    const dirtinessScope = React.useRef(null);
    /**
     * Selection data passed into the actions dropdown as a single merged object
     */
    const mergedSelectionData = React.useMemo(() => {
        const targetGrid = selectedRowsByGrids.at(0);
        if (!targetGrid)
            return {};
        let datalessIds = new Set();
        // selectedIds is a superset of selectedRows, if they're not the same size, we should get a proper union
        if (targetGrid.selectedIds.size !== targetGrid.selectedRows.length) {
            datalessIds = new Set(targetGrid.selectedIds);
            targetGrid.selectedRows.forEach((r) => {
                const rowId = GetValueFromData(r, targetGrid.primaryKeyField);
                datalessIds.delete(String(rowId));
            });
        }
        return MergeExpressionData(...targetGrid.selectedRows, ...Array.from(datalessIds).map((id) => ({ [targetGrid.primaryKeyField]: id })));
    }, [selectedRowsByGrids]);
    const entitledViewSet = useEntitledViewSet(rawViewSet, primaryKeyValue);
    const { tabs: viewSetTabs } = entitledViewSet;
    const viewSetTabsById = React.useMemo(() => {
        if (!viewSetTabs)
            return {};
        return viewSetTabs.reduce((acc, tab) => {
            acc[tab.id] = tab;
            return acc;
        }, {});
    }, [viewSetTabs]);
    const screenRequiresRecordSelection = React.useMemo(() => {
        if (!entitledViewSet.domainId)
            return false;
        if ((currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.type) !== ViewType.Form)
            return false;
        return true;
    }, [currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.type, entitledViewSet.domainId]);
    const selectedTab = selectedTabId ? viewSetTabsById[selectedTabId] : undefined;
    const mergedFilter = React.useMemo(() => [...(filters !== null && filters !== void 0 ? filters : []), baselineFilterExpression].filter((e) => typeof e === 'string'), [filters, baselineFilterExpression]);
    const customizedActions = React.useMemo(() => {
        var _a;
        // If screen requires record selection but there's no record, hide all actions
        if (screenRequiresRecordSelection && primaryKeyValue === undefined)
            return [];
        return (_a = entitledViewSet.actions) === null || _a === void 0 ? void 0 : _a.map((action) => (Object.assign(Object.assign({}, action), { primaryKeyValue })));
    }, [entitledViewSet.actions, primaryKeyValue, screenRequiresRecordSelection]);
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(domainId);
    const tabOverrideContext = useTabOverrides();
    const actionButton = React.useCallback(() => (React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
        React.createElement(TabOverrideContextProvider, { tabRenderer: tabOverrideContext === null || tabOverrideContext === void 0 ? void 0 : tabOverrideContext.tabRenderer, renderingViewSetId: viewSetId },
            React.createElement(ControlBarActions, { data: data, afterDelete: afterDelete, dirtinessScope: dirtinessScope, actionItems: filterActions(customizedActions, entitledViewSet === null || entitledViewSet === void 0 ? void 0 : entitledViewSet.tabs, selectedTabId), onChangePrimaryKey: setPrimaryKeyValue, filters: filters, defaultData: defaultData, primaryKeyValue: primaryKeyValue, selectedData: mergedSelectionData, ViewModal: ViewModal, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper })))), [
        evaluatorConfig,
        tabOverrideContext === null || tabOverrideContext === void 0 ? void 0 : tabOverrideContext.tabRenderer,
        viewSetId,
        data,
        afterDelete,
        customizedActions,
        entitledViewSet === null || entitledViewSet === void 0 ? void 0 : entitledViewSet.tabs,
        selectedTabId,
        setPrimaryKeyValue,
        filters,
        defaultData,
        primaryKeyValue,
        mergedSelectionData,
        ServiceDrivenViewSet,
    ]);
    /**
     * Handles evaluation of tab-related expressions from the {@link ViewSet} component
     * @param ev the tab processed event
     */
    const onTabProcessed = (evs) => {
        setTabExpressionMap((prev) => {
            const output = Object.assign({}, prev);
            evs.forEach((_a) => {
                var { tabId } = _a, expressionResults = __rest(_a, ["tabId"]);
                output[tabId] = expressionResults;
            });
            return output;
        });
    };
    // update tabs
    React.useEffect(() => {
        setHeaderValue('tabs', viewSetTabs
            .map(({ id }) => {
            const expressionResults = tabExpressionMap[id];
            if (!expressionResults)
                return undefined;
            const { title, isVisible } = expressionResults;
            if (!isVisible)
                return undefined;
            return {
                id,
                header: title,
            };
        })
            .filter((t) => t !== undefined));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewSetTabs, tabExpressionMap]);
    // select an initial tab using provided id (if valid) or first id
    React.useEffect(() => {
        let targetId = initialSelectedTabId;
        const viewSetTabIds = viewSetTabs.map((t) => t.id);
        if (!targetId || !viewSetTabs.some((t) => t.id === targetId)) {
            [targetId] = viewSetTabIds;
        }
        if (targetId && targetId !== selectedTabId) {
            setHeaderValue('selectedTabId', targetId);
            if (onTabChanged)
                onTabChanged(targetId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedTabId]);
    // update header body
    const { recordPickerListViewId } = currentLayout !== null && currentLayout !== void 0 ? currentLayout : {};
    React.useEffect(() => {
        if (screenRequiresRecordSelection) {
            setHeaderValue('pageTitle', React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
                React.createElement(RecordDropdown, { domainId: domainId, primaryKeyValue: primaryKeyValue, onValueChanged: setPrimaryKeyValue, filters: filters, listViewId: recordPickerListViewId })));
        }
        else if (selectedTab)
            setHeaderValue('pageTitle', selectedTab.title);
    }, [
        domainId,
        evaluatorConfig,
        filters,
        primaryKeyValue,
        screenRequiresRecordSelection,
        selectedTab,
        recordPickerListViewId,
        setHeaderValue,
        setPrimaryKeyValue,
    ]);
    // update actions
    React.useEffect(() => {
        setHeaderValue('controlBarProps', (c) => (Object.assign(Object.assign({}, c), { additionalControls: actionButton, precedingControls: precedingControlRenderer })));
    }, [actionButton, precedingControlRenderer, setHeaderValue]);
    if (!((_a = entitledViewSet.tabs) === null || _a === void 0 ? void 0 : _a.length)) {
        return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Empty Page", text: `No tabs available under the "${viewSetName !== null && viewSetName !== void 0 ? viewSetName : viewSetId}" page` }));
    }
    return (React.createElement("div", { ref: dirtinessScope, className: css({ display: 'flex', height: '100%', flex: '1' }) },
        React.createElement(ViewSet, { tabs: viewSetTabsById, primaryKeyValue: primaryKeyValue, filters: mergedFilter, defaultData: defaultData, data: data, baselineFilterExpression: baselineFilterExpression, onSelectionChanges: onSelectionChanges, parentScope: scope, useFormView: useFormView, submitAllAttributes: submitAllAttributes, initialSelectedSubtabId: initialSelectedSubtabId, onSubtabChanged: onSubtabChanged, ServiceDrivenViewSet: ServiceDrivenViewSet, onLayoutChanged: setCurrentLayout, onTabsProcessed: onTabProcessed })));
};
export default ViewSetWithHeader;
