import React from 'react';
import { v4 as uuid } from 'uuid';
import { PicklistDisplaySetting, PicklistSetting, PicklistSortType, InputType, } from '@samc/picklist-api';
import { PicklistFieldCrudEditorPanel } from '@samc/picklist-core';
import { SelectEntryField, useFormContext } from '@samc/react-ui-form';
import { Button } from '@samc/react-ui-core';
import { PicklistListViewPicker } from '../../../atoms/PicklistListViewPicker/PicklistListViewPicker';
import { FetchItems } from '../../../hooks/useConfigureFieldsMeta/fetchItems';
const generateDefaultPicklistField = () => {
    const output = {
        id: uuid(),
        picklistId: '',
        displaySetting: PicklistDisplaySetting.CustomShortName,
        setting: PicklistSetting.Single,
        sortType: PicklistSortType.SortOrder,
    };
    return output;
};
export const InputTypeField = (props) => {
    const { domain, data, value, onChange, disabled } = props;
    const { picklistField: _inputPicklistField } = data !== null && data !== void 0 ? data : {};
    const { fields: domainFields, id: domainId } = domain;
    const { onChange: onChangeData } = useFormContext();
    const defaultedPicklistField = React.useMemo(() => _inputPicklistField !== null && _inputPicklistField !== void 0 ? _inputPicklistField : generateDefaultPicklistField(), [_inputPicklistField]);
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    // eslint-disable-next-line eqeqeq
    const showPicklistButton = value == InputType.Picklist;
    const domainField = React.useMemo(() => domainFields === null || domainFields === void 0 ? void 0 : domainFields.find((f) => f.fieldName === (data === null || data === void 0 ? void 0 : data.viewFieldName)), [domainFields, data]);
    const items = React.useMemo(() => FetchItems.fetchInputTypes('id', 'type', domainField).filter((t) => t.type !== 'MultiSelect'), [domainField]);
    const onSelectionChange = (item) => {
        if (item)
            onChange(item.id);
        else
            onChange(undefined);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', width: '100%' } },
            React.createElement(SelectEntryField, { valueProperty: "id", displayProperty: "type", menuItems: items, onChange: onSelectionChange, defaultSelectedKey: value, disabled: disabled }),
            showPicklistButton && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { padding: 5 } }),
                React.createElement(Button, { text: "Configure", buttonType: "secondary", onClick: () => setIsPanelOpen(true) })))),
        React.createElement(PicklistFieldCrudEditorPanel, { isOpen: isPanelOpen, onDismiss: () => setIsPanelOpen(false), onCancel: () => setIsPanelOpen(false), onSubmit: (picklistField) => onChangeData('picklistField', picklistField, true).then() // update form data
            , picklistField: defaultedPicklistField, isNew // allows type changes
            : true, listViewPickerRenderer: PicklistListViewPicker, targetDomainId: domainId })));
};
export default InputTypeField;
