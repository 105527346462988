var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useQueryClient } from 'react-query';
import { ServersideFolderViewer, isFolder } from '@samc/folder-ui-core';
import { ApiContext } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { InputType, PicklistDisplaySetting, PicklistSetting, } from '@samc/picklist-api';
import { getServerSidePicklistFieldRenderer, usePicklistGridFields } from '@samc/picklist-core';
import { InputType as FormInputType } from '@samc/react-ui-form';
import { FolderType } from '@samc/folder-ui-api';
import * as Utils from './Utilities';
import { FilterEditorModal } from '../../../molecules/modals/FilterEditorModal/FilterEditorModal';
import { usePicklistGridFieldConfigurations } from '../../../hooks/usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { useGridFieldsFromListview } from '../../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import { useGridInjector } from '../../../hooks/useGridInjector/useGridInjector';
const POMA_PORTFOLIO_PICKLIST_ID = 'POMA_Portfolio_Picklist';
const portfolioPicklistField = {
    picklistId: POMA_PORTFOLIO_PICKLIST_ID,
    displaySetting: PicklistDisplaySetting.CustomLongName,
    id: '_internal_portfolio_picklist',
    setting: PicklistSetting.MultiSelect,
};
const picklistFieldConfigurationMembers = {
    tag: {
        viewFieldName: 'tag',
        inputType: InputType.Picklist,
        picklistField: portfolioPicklistField,
    },
};
const picklistGridFields = [
    {
        field: 'tag',
        headerName: 'Portfolios',
        floatingFilter: true,
        valueGetter: ({ data }) => {
            const mappedData = data;
            if (!isFolder(mappedData))
                return null;
            if (mappedData.tagType !== 'Portfolio')
                return null;
            return mappedData.tag;
        },
    },
];
const picklistFormFields = [
    {
        field: 'tag',
        displayNameRule: "'Portfolios'",
        inputType: FormInputType.Text,
        width: 200,
        editableRule: `[type] <> ${FolderType.System}`,
        onRenderField: getServerSidePicklistFieldRenderer(portfolioPicklistField, [], usePicklistGridFieldConfigurations.withHooks({ useGridFieldsFromListview, useGridInjector })),
    },
    {
        field: 'tagType',
        visibleRule: 'false',
        calculationRule: "IIF(ISNULLOREMPTY([tag]), null, 'Portfolio')",
        calculationCondition: 'true',
        inputType: FormInputType.Text,
    },
];
export const GridFilterFolderEditor = (props) => {
    const { domainId, className, editOnClick, multiselect } = props;
    const queryClient = useQueryClient();
    const [css] = useStyletron();
    const [targetGridFilterId, setTargetGridFilterId] = React.useState();
    const [newGridFilterFolderId, setNewGridFilterFolderId] = React.useState();
    const api = React.useContext(ApiContext);
    const getFilterAndCopyToClipboard = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const resp = yield fetch(`/domainmetadata/api/v1/Filter/${id}`, api.requestInit);
            const capitalizedJsonString = JSON.stringify(yield resp.json(), undefined, 2).replace(/"([\w]+)":/g, (_match, group) => `"${group.charAt(0).toUpperCase()}${group.slice(1)}":`);
            navigator.clipboard.writeText(capitalizedJsonString);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    });
    const { frameworkComponents, wrappedFields } = usePicklistGridFields({
        fieldConfigurationMembers: picklistFieldConfigurationMembers,
        gridFields: picklistGridFields,
    });
    const extraColDefs = React.useMemo(() => {
        return wrappedFields.map((f) => (Object.assign(Object.assign({}, f), { tooltipComponentParams: f, hide: undefined })));
    }, [wrappedFields]);
    const iProps = {
        setTargetGridFilterId,
        queryClient,
        setNewGridFilterFolderId,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ServersideFolderViewer, { className: className, extraColumns: extraColDefs, frameworkComponents: frameworkComponents, spaceName: domainId, multiselect: multiselect, suppressCheckbox: editOnClick, typeDisplayName: "Filter", typeDisplayNamePlural: "Filters", type: "DomainFilter", rowClassRules: { [css(Object.assign({}, (editOnClick && { cursor: 'pointer' })))]: (v) => !isFolder(v.data) }, getContextMenuItems: Utils.getContextMenuItems(iProps, getFilterAndCopyToClipboard), onRowClicked: Utils.onRowClicked(props, iProps), folderEditorProps: { additionalFields: picklistFormFields } }),
        (!isNullOrUndefined(targetGridFilterId) || !isNullOrUndefined(newGridFilterFolderId)) && (React.createElement(FilterEditorModal, { defaultFolderId: newGridFilterFolderId, onClose: Utils.onClose(iProps), filterId: targetGridFilterId, suppressApply // apply causes a failed load due to not having a grid open
            : true }))));
};
export default GridFilterFolderEditor;
