var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { InputType, LayoutType, getBoundedFormSkeleton } from '@samc/react-ui-form';
import { ViewType } from '@samc/screen-config-api';
import { getEditableFolderSelectorFieldRenderer } from './EditableFolderSelectorField';
const MenuItems = [
    { key: ViewType.Form, text: 'Form View' },
    { key: ViewType.Grid, text: 'List View' },
];
export const getViewsetLayoutSettingsForm = (params) => {
    const { domainId, onEditFormView, onEditListView } = params;
    const output = getBoundedFormSkeleton({
        title: 'View Set Layout Settings',
        fields: {
            title: {
                inputType: InputType.Text,
                field: 'title',
                displayNameRule: "'Layout Name'",
                editableRule: 'true',
            },
            type: {
                inputType: InputType.Select,
                field: 'type',
                displayNameRule: "'View Type'",
                menuItems: MenuItems,
                displayProperty: 'text',
                valueProperty: 'key',
                editableRule: 'true',
            },
            formViewId: {
                inputType: InputType.Text,
                field: 'formViewId',
                displayNameRule: "'Form View'",
                visibleRule: `[type] = ${ViewType.Form}`,
                editableRule: 'true',
                onRenderField: getEditableFolderSelectorFieldRenderer({
                    spaceName: domainId,
                    itemType: 'FormView',
                    onEdit: onEditFormView,
                    editTooltip: 'Edit Form View',
                }),
            },
            listViewId: {
                inputType: InputType.Text,
                field: 'listViewId',
                displayNameRule: "'List View'",
                visibleRule: `[type] = ${ViewType.Grid}`,
                editableRule: 'true',
                onRenderField: getEditableFolderSelectorFieldRenderer({
                    spaceName: domainId,
                    itemType: 'DomainView',
                    onEdit: onEditListView,
                    editTooltip: 'Edit List View',
                }),
            },
            recordPickerListViewId: {
                inputType: InputType.Text,
                field: 'recordPickerListViewId',
                displayNameRule: "'Record Picker List View'",
                visibleRule: `[type] = ${ViewType.Form}`,
                editableRule: 'true',
                // clear on type change
                calculationRule: 'null',
                calculationCondition: `[type] <> ${ViewType.Form}`,
                onRenderField: getEditableFolderSelectorFieldRenderer({
                    spaceName: domainId,
                    itemType: 'DomainView',
                    onEdit: onEditListView,
                    editTooltip: 'Edit Record Picker List View',
                }),
            },
        },
        tabs: [
            {
                title: 'Layout Settings',
                sections: [
                    {
                        titleExpression: "'Layout Settings'",
                        fieldLayout: {
                            type: LayoutType.VerticalStack,
                            children: [
                                {
                                    field: 'title',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'type',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'formViewId',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'listViewId',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                                {
                                    field: 'recordPickerListViewId',
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                },
                            ],
                        },
                    },
                ],
                layout: {
                    type: LayoutType.VerticalStack,
                    children: [
                        {
                            index: 0,
                            children: [],
                            type: LayoutType.VerticalStack,
                        },
                    ],
                },
            },
        ],
        gridFields: [],
    });
    return output;
};
export const ViewSetLayoutFormMappers = {
    toFormData: (data) => {
        if (!data)
            return {};
        const { viewId } = data, rest = __rest(data, ["viewId"]);
        const { type } = data;
        let formViewId;
        let listViewId;
        switch (type) {
            case ViewType.Form:
                formViewId = viewId;
                break;
            case ViewType.Grid:
                listViewId = viewId;
                break;
            default:
                break;
        }
        return Object.assign(Object.assign({}, rest), { formViewId,
            listViewId });
    },
    fromFormData: (data) => {
        const { formViewId, listViewId } = data, rest = __rest(data, ["formViewId", "listViewId"]);
        const { type } = data;
        let viewId;
        switch (type) {
            case ViewType.Form:
                viewId = formViewId;
                break;
            case ViewType.Grid:
                viewId = listViewId;
                break;
            default:
                break;
        }
        return Object.assign(Object.assign({ 
            // defaults
            title: '', type: ViewType.Form, viewId: '' }, rest), (viewId && {
            viewId,
        }));
    },
};
export default {};
