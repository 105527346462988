var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ConvertGridFilterToFilterExpression, GridDropdown, } from '@samc/react-ui-grid';
import { GetValueFromData } from '@samc/react-ui-core';
import './BaseRecordDropdown.css';
const EMPTY_SELECTED_KEYS = [];
export const BaseRecordDropdown = (props) => {
    const { doFetch, primaryKeyField, frameworkComponents, fields, selectedKeys = EMPTY_SELECTED_KEYS, onSelectionChanged, filters, displayFieldName, } = props;
    const ref = React.useRef(null);
    const handleChange = (ev) => {
        const { selectedOptions } = ev;
        onSelectionChanged(selectedOptions.map((option) => option.id));
    };
    const resolveSelection = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const [response] = yield doFetch(false, {
            start: 0,
            stop: 1,
        }, {
            expressionLang: 'Centric',
            advancedExpression: ids.map((id) => `[${primaryKeyField}] = '${id}'`).join(' OR '),
            advancedInd: true,
        }, undefined, undefined, [
            {
                scalarDisplayName: displayFieldName,
                scalarExpression: `[${displayFieldName}]`,
            },
        ]);
        const { Data } = response;
        if (Data.length === 0)
            onSelectionChanged(EMPTY_SELECTED_KEYS); // selection is bad
        return Data.map((d) => ({
            id: String(GetValueFromData(d, primaryKeyField)),
            displayText: String(GetValueFromData(d, displayFieldName)),
            data: d,
        }));
    });
    const getOptions = (params) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { offset, filter } = params;
        const { limit, filterModel, sortModel } = filter !== null && filter !== void 0 ? filter : {};
        const { sort: order, colId: orderBy } = (_a = sortModel === null || sortModel === void 0 ? void 0 : sortModel.at(0)) !== null && _a !== void 0 ? _a : {};
        const [response] = yield doFetch(false, {
            start: offset,
            stop: offset + (limit !== null && limit !== void 0 ? limit : 100),
            order,
            orderBy,
        }, Object.keys(filterModel !== null && filterModel !== void 0 ? filterModel : {}).length > 0
            ? {
                advancedExpression: ConvertGridFilterToFilterExpression(filterModel, fields) || 'true',
                expressionLang: 'Centric',
                filterName: 'AdHoc',
                advancedInd: true,
            }
            : undefined, undefined, undefined, [
            {
                scalarDisplayName: displayFieldName,
                scalarExpression: `[${displayFieldName}]`,
            },
        ]);
        const options = (response.Data || []).map((d) => ({
            id: String(d.primaryKey),
            data: d,
            displayText: String(GetValueFromData(d, displayFieldName)),
        }));
        return {
            options,
            totalCount: response.TotalRecords || 0,
        };
    });
    // force reload on filter change
    const filterChangedKey = filters === null || filters === void 0 ? void 0 : filters.join('');
    React.useEffect(() => {
        const refVal = ref.current;
        if (!refVal)
            return;
        refVal.reload();
    }, [filterChangedKey]);
    return (React.createElement("div", { className: "screen-config-record-dropdown", style: { minWidth: '250px' } },
        React.createElement(GridDropdown, { getOptions: getOptions, fields: fields, onChange: handleChange, selectedKeys: selectedKeys, placeholder: "Select a record", multiSelect: false, selectRowOnClick: true, resolveSelection: resolveSelection, ref: ref, frameworkComponents: frameworkComponents })));
};
export default BaseRecordDropdown;
