import React from 'react';
/* istanbul ignore next */
export const useDropzoneGridIds = (dropzoneGridDefs, onFileAddedCb) => {
    const [dropzoneFileData, setDropzoneFileData] = React.useState({});
    const onDZGridFileDrop = React.useCallback((acceptedFiles, fileRejections, event, dropzoneId) => {
        const curDropzoneRowDef = dropzoneGridDefs === null || dropzoneGridDefs === void 0 ? void 0 : dropzoneGridDefs.rowDefs.find((rowDef) => rowDef[dropzoneGridDefs.idField] === dropzoneId);
        if (curDropzoneRowDef && dropzoneGridDefs) {
            setDropzoneFileData((prevData) => {
                const newDzData = {};
                const dzDataVals = dropzoneGridDefs.dataFieldsDropzone.map((dataField) => {
                    return {
                        dropzoneFieldVal: curDropzoneRowDef[dataField.mapFromDzField] || dataField.mapFromDzField,
                        gridFieldAssignment: dataField.mapToGridField,
                    };
                });
                acceptedFiles.forEach((file) => {
                    newDzData[file.path || ''] = dzDataVals;
                });
                return Object.assign(Object.assign({}, prevData), newDzData);
            });
            dropzoneGridDefs.onDrop(acceptedFiles, fileRejections, event, dropzoneId);
        }
    }, [dropzoneGridDefs]);
    const onStrataFileAdded = React.useCallback((status, extraData) => {
        var _a;
        let dzDataVals = [];
        const newStatus = Object.assign({}, status);
        const valuationAssetDocTypes = [
            'DEBTVALUATIONDOCUMENT',
            'LOANAMENDMENTS',
            'LOANDOCUMENTS/AGREEMENTS',
            'LOANSTATEMENT',
            'OTHEREQUITYDOCUMENTS',
            'OTHERDEBTDOCS',
            'PARTNERSHIPAGREEMENTS',
            'WATERFALLANALYSIS',
            'WORKINGCAPITAL',
            'COSTSUMMARIES',
        ];
        if (dropzoneGridDefs) {
            dzDataVals = dropzoneGridDefs.dataFields.map((dataField) => {
                return {
                    dropzoneFieldVal: dataField.mapFromDzField,
                    gridFieldAssignment: dataField.mapToGridField,
                };
            });
        }
        let savedDzDataVals = dropzoneFileData[`${status.path}`] || dzDataVals;
        const dropZoneExtraData = {
            docTypes: (extraData === null || extraData === void 0 ? void 0 : extraData.docTypes) || [],
            valuationAsset: (extraData === null || extraData === void 0 ? void 0 : extraData.valuationAsset) || '',
        };
        if (((_a = dropZoneExtraData === null || dropZoneExtraData === void 0 ? void 0 : dropZoneExtraData.docTypes) === null || _a === void 0 ? void 0 : _a.length) === 1) {
            savedDzDataVals = [
                ...savedDzDataVals,
                {
                    dropzoneFieldVal: dropZoneExtraData.docTypes[0].id,
                    gridFieldAssignment: 'DocumentTypeId',
                },
            ];
        }
        const docTypeData = savedDzDataVals.filter((field) => field.gridFieldAssignment === 'DocumentTypeId')[0];
        const hasValuationAssetDocType = valuationAssetDocTypes.indexOf(docTypeData === null || docTypeData === void 0 ? void 0 : docTypeData.dropzoneFieldVal) !== -1;
        if ((dropZoneExtraData === null || dropZoneExtraData === void 0 ? void 0 : dropZoneExtraData.valuationAsset) && hasValuationAssetDocType) {
            savedDzDataVals = [
                ...savedDzDataVals,
                {
                    dropzoneFieldVal: dropZoneExtraData.valuationAsset,
                    gridFieldAssignment: 'ValuationAssetId',
                },
            ];
        }
        if (savedDzDataVals.length) {
            savedDzDataVals.forEach(({ dropzoneFieldVal, gridFieldAssignment }) => {
                newStatus[gridFieldAssignment] = dropzoneFieldVal;
            });
            setDropzoneFileData((prevIds) => {
                const newIds = Object.assign({}, prevIds);
                delete newIds[`${status.path}`];
                return newIds;
            });
        }
        if (onFileAddedCb) {
            onFileAddedCb(newStatus);
        }
        return newStatus;
    }, [dropzoneFileData, dropzoneGridDefs, onFileAddedCb]);
    return {
        onDZGridFileDrop,
        onStrataFileAdded,
    };
};
export default useDropzoneGridIds;
