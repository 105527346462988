import React from 'react';
import { ExpressionHandler } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
/**
 * A hook whose responsibility is evaluating all of the expressions within a ViewSet's tabs and returning the current tab and layout.
 * This is useful for rendering the correct layout for a tab based on the evaluated expressions.
 */
export const useCurrentViewSetLayout = (params) => {
    const { tabs, tabId, data, onTabsProcessed, onLayoutChanged } = params;
    const [tabResultMap, setTabResultMap] = React.useState({});
    const tabEventQueue = React.useRef([]);
    let _currentTab;
    let _currentTabExpressionResults;
    if (tabId) {
        _currentTab = tabs[tabId];
        _currentTabExpressionResults = tabResultMap[tabId];
    }
    /**
     * Merges all tabs with their evaluated expressions, and filters out any tabs that don't have evaluated expressions
     */
    const allTabs = React.useMemo(() => {
        return Object.entries(tabs).reduce((acc, [_tabId, _tab]) => {
            const _tabResult = tabResultMap[_tabId];
            if (!_tabResult)
                return acc;
            return Object.assign(Object.assign({}, acc), { [_tabId]: {
                    tab: _tab,
                    expressionResults: _tabResult,
                } });
        }, {});
    }, [tabs, tabResultMap]);
    const currentLayout = React.useMemo(() => {
        var _a;
        if (!_currentTab)
            return undefined;
        const { defaultLayout, layoutSelectionExpression } = _currentTab;
        if (!layoutSelectionExpression)
            return defaultLayout;
        if (!_currentTabExpressionResults)
            return undefined;
        const { layoutSelectionString } = _currentTabExpressionResults;
        // find the layout that matches the layout selection string, or default to the default layout
        return (_a = _currentTab.layouts.find((layout) => layout.title === layoutSelectionString)) !== null && _a !== void 0 ? _a : defaultLayout;
    }, [_currentTab, _currentTabExpressionResults]);
    const evaluator = useEvaluator(undefined, true);
    const expressionHandler = React.useRef(new ExpressionHandler({
        initialExpressionData: data,
        evaluator,
        initialGlobalHandler: (ev) => {
            const { optionEvents } = ev;
            if (!optionEvents.length)
                return;
            setTabResultMap((curTabResultMap) => {
                const newTabResultMap = Object.assign({}, curTabResultMap);
                optionEvents.forEach((event) => {
                    const { evaluatedExpression, expressionField, optionId: _tabId } = event;
                    const previousTabResult = newTabResultMap[_tabId];
                    const newTabResult = Object.assign({ isVisible: true, layoutSelectionString: undefined, title: 'Loading...' }, previousTabResult);
                    switch (expressionField) {
                        case 'visibleExpression':
                            newTabResult.isVisible = !!evaluatedExpression;
                            break;
                        case 'layoutSelectionExpression':
                            newTabResult.layoutSelectionString = String(evaluatedExpression);
                            break;
                        case 'titleExpression':
                            newTabResult.title = String(evaluatedExpression);
                            break;
                        default:
                            break;
                    }
                    tabEventQueue.current.push(Object.assign({ tabId: _tabId }, newTabResult));
                    newTabResultMap[_tabId] = newTabResult;
                });
                return newTabResultMap;
            });
        },
    }));
    const currentEvaluatedTab = React.useMemo(() => {
        if (!_currentTab || !_currentTabExpressionResults)
            return undefined;
        return {
            tab: _currentTab,
            expressionResults: _currentTabExpressionResults,
        };
    }, [_currentTab, _currentTabExpressionResults]);
    // clear tab event queue, we do this in an effect to ensure it is processed at the correct time
    React.useEffect(() => {
        const eventsToEmit = [...tabEventQueue.current];
        tabEventQueue.current = [];
        if (onTabsProcessed)
            onTabsProcessed(eventsToEmit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabResultMap]);
    // register expressions
    React.useEffect(() => {
        Object.values(tabs).forEach((tab) => {
            const { visibleExpression, layoutSelectionExpression, titleExpression, title } = tab;
            expressionHandler.current.registerOptionExpressions({
                managedExpressions: {
                    visibleExpression,
                    layoutSelectionExpression,
                    titleExpression: titleExpression || `'${title}'`,
                },
                optionId: tab.id,
                emitInitialEvents: true,
            });
        });
    }, [tabs]);
    // ensure expression handler has newest evaluator
    React.useEffect(() => {
        expressionHandler.current.setEvaluator(evaluator);
    }, [evaluator]);
    // keep expression data up to date
    React.useEffect(() => {
        expressionHandler.current.updateExpressionData(data);
    }, [data]);
    // call layout changed handler
    React.useEffect(() => {
        if (onLayoutChanged)
            onLayoutChanged(currentLayout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLayout]);
    return {
        tab: currentEvaluatedTab,
        layout: currentLayout,
        allTabs,
    };
};
export default useCurrentViewSetLayout;
