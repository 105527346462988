var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import SitusHeader from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import React from 'react';
import { DataEntryForm, InputType, LayoutType, useFormApi, } from '@samc/react-ui-form';
import { useFormViews, ViewType, useDomain } from '@samc/screen-config-api';
import { toastError } from '@samc/react-ui-core';
import { useLinkClicked } from '../../../../../hooks/useLinkClicked/useLinkClicked';
import { expressionIsValidForDomain } from '../../../../../utils/ExpressionValidation/ExpressionValidationUtilities';
import { getExpressionFormField } from '../../../../../atoms/ExpressionField/ExpressionField';
const getLayoutMenuItems = (layouts) => {
    const layoutMenuItems = [];
    if (layouts)
        layouts.forEach((layout) => {
            layoutMenuItems.push({ id: layout.viewId, text: layout.title });
        });
    return layoutMenuItems;
};
const getSelectedLayout = (layoutId, layouts) => {
    return layouts.filter((x) => x.viewId === layoutId)[0];
};
export const ViewSetTabSettings = (props) => {
    var _a;
    const { tabSettingsData, onFormSubmit, onFormCancel, domainId } = props;
    const { data } = useFormViews(domainId);
    const domain = useDomain(domainId);
    const showBaselineFilterExpression = React.useMemo(() => {
        var _a;
        if (((_a = tabSettingsData.defaultLayout) === null || _a === void 0 ? void 0 : _a.title) === '') {
            return 'false';
        }
        return 'true';
    }, [tabSettingsData]);
    const expressionRendererGetter = React.useCallback((fieldName) => (!domainId ? () => React.createElement(React.Fragment, null) : getExpressionFormField(domainId, fieldName)), [domainId]);
    const tabSettingsFields = React.useMemo(() => {
        var _a;
        return ({
            id: {
                field: 'id',
                visibleRule: 'false',
                isKeyField: true,
                inputType: InputType.Text,
            },
            title: {
                field: 'title',
                requiredFieldRule: 'true',
                displayNameRule: "'Tab Name'",
                editableRule: 'true',
                inputType: InputType.Text,
                width: 300,
            },
            titleExpression: {
                field: 'titleExpression',
                displayNameRule: "'Tab Label Rule'",
                editableRule: 'true',
                inputType: InputType.Expression,
                onRenderField: expressionRendererGetter('titleExpression'),
                width: 300,
            },
            visibleExpression: {
                field: 'visibleExpression',
                displayNameRule: "'Visibility Rule'",
                requiredFieldRule: 'false',
                editableRule: 'true',
                inputType: InputType.Expression,
                onRenderField: expressionRendererGetter('visibleExpression'),
                width: 300,
            },
            'defaultLayout.viewId': {
                inputType: InputType.Select,
                field: 'defaultLayout.viewId',
                width: 300,
                visibleRule: 'true',
                requiredFieldRule: 'false',
                editableRule: 'true',
                displayNameRule: "'Default Layout'",
                menuItems: getLayoutMenuItems(tabSettingsData.layouts),
                displayProperty: 'text',
                valueProperty: 'id',
            },
            baselineFilterExpression: {
                inputType: InputType.Expression,
                onRenderField: expressionRendererGetter('baselineFilterExpression'),
                field: 'baselineFilterExpression',
                visibleRule: showBaselineFilterExpression,
                displayNameRule: "'Baseline Filter Expression'",
                requiredFieldRule: 'false',
                editableRule: 'true',
                width: 300,
                extraProps: { fields: (_a = domain.data) === null || _a === void 0 ? void 0 : _a.fields.map((f) => f.fieldName) },
            },
            wrapText: {
                inputType: InputType.Checkbox,
                field: 'controlVisibilities.wrapText',
                width: 300,
                visibleRule: tabSettingsData.layouts.some((l) => l.type === ViewType.Form) ? 'false' : 'true',
                requiredFieldRule: 'false',
                editableRule: 'true',
                displayNameRule: "'Wrap Text on Grid'",
            },
            layoutSelectionExpression: {
                field: 'layoutSelectionExpression',
                displayNameRule: "'Layout Selection Rule'",
                requiredFieldRule: 'false',
                editableRule: 'true',
                inputType: InputType.Expression,
                onRenderField: expressionRendererGetter('layoutSelectionExpression'),
                width: 300,
            },
            sidePanelForm: {
                field: 'sidePanelForm',
                displayNameRule: "'Side Panel Form'",
                requiredFieldRule: 'false',
                editableRule: 'true',
                tooltip: 'The form to display in the side panel (only renders on form views)',
                inputType: InputType.Select,
                onFetchMenuItems: () => __awaiter(void 0, void 0, void 0, function* () {
                    return new Promise((res) => {
                        if (data)
                            res(data);
                    });
                }),
                valueProperty: 'id',
                displayProperty: 'name',
            },
        });
    }, [expressionRendererGetter, tabSettingsData.layouts, showBaselineFilterExpression, (_a = domain.data) === null || _a === void 0 ? void 0 : _a.fields, data]);
    const formSections = React.useMemo(() => [
        {
            titleExpression: tabSettingsData.title,
            fieldLayout: {
                type: LayoutType.VerticalStack,
                children: [
                    { field: 'title', type: LayoutType.VerticalStack, children: [] },
                    { field: 'titleExpression', type: LayoutType.VerticalStack, children: [] },
                    { field: 'visibleExpression', type: LayoutType.VerticalStack, children: [] },
                    { field: 'defaultLayout.viewId', type: LayoutType.VerticalStack, children: [] },
                    { field: 'baselineFilterExpression', type: LayoutType.VerticalStack, children: [] },
                    { field: 'wrapText', type: LayoutType.VerticalStack, children: [] },
                    { field: 'layoutSelectionExpression', type: LayoutType.VerticalStack, children: [] },
                    ...(tabSettingsData.layouts.some((l) => l.type === ViewType.Form)
                        ? [{ field: 'sidePanelForm', type: LayoutType.VerticalStack, children: [] }]
                        : []),
                ],
            },
        },
    ], [tabSettingsData.layouts, tabSettingsData.title]);
    const tabSettingsForm = React.useMemo(() => ({
        title: 'Test Form',
        fields: tabSettingsFields,
        gridFields: [],
        tabs: [
            {
                title: 'Tab 1',
                baselineFilterExpression: null,
                sections: formSections,
                layout: {
                    type: LayoutType.VerticalStack,
                    children: [
                        {
                            type: LayoutType.HorizontalStack,
                            children: [{ index: 0, type: LayoutType.VerticalStack, children: [] }],
                        },
                    ],
                },
            },
        ],
    }), [formSections, tabSettingsFields]);
    const [formData] = React.useState(Object.assign({}, tabSettingsData));
    const [formApi, onFormLoaded] = useFormApi();
    const handleSubmit = (updatedData) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const _updatedData = Object.assign({}, updatedData);
        if ((_b = _updatedData === null || _updatedData === void 0 ? void 0 : _updatedData.defaultLayout) === null || _b === void 0 ? void 0 : _b.viewId) {
            _updatedData.defaultLayout = getSelectedLayout(_updatedData.defaultLayout.viewId, tabSettingsData.layouts);
        }
        if (domain.data &&
            _updatedData.baselineFilterExpression &&
            !expressionIsValidForDomain(_updatedData.baselineFilterExpression, domain.data)) {
            toastError('Invalid expression for Baseline Filter');
            return;
        }
        onFormSubmit(_updatedData);
    });
    const [onLinkClicked] = useLinkClicked();
    return (React.createElement("div", { style: {
            height: '70vh',
            width: '50vw',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement(SitusHeader, { title: "Tab Settings", confirmationButtonText: "Save", onConfirmationButtonClicked: () => {
                formApi === null || formApi === void 0 ? void 0 : formApi.submitForm();
            }, rejectionButtonText: "Cancel", onRejectionButtonClicked: onFormCancel }),
        React.createElement(DataEntryForm, { formConfig: tabSettingsForm, initialData: formData, onSubmit: handleSubmit, onLinkClicked: onLinkClicked, onFormLoaded: onFormLoaded })));
};
export default ViewSetTabSettings;
