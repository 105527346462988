var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/display-name */
import React from 'react';
import { Grid } from '@samc/react-ui-grid';
import { InputType } from '@samc/picklist-api';
import { TextEntryField } from '@samc/react-ui-form';
import { FieldLabel } from '@samc/react-ui-form/lib/atoms/FieldLabel/FieldLabel';
import { useStyletron } from 'styletron-react';
import { cloneDatum } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { ClassNameBuilder, useDirtinessPrompt, useDirtinessSync } from '@samc/react-ui-core';
import { useConfigureFieldsMeta } from '../../../hooks/useConfigureFieldsMeta/useConfigureFieldsMeta';
import { FieldConfigurationCrudEditorPanel } from '../../panels/FieldConfigurationCrudEditorPanel/FieldConfigurationCrudEditorPanel';
import { ValidationSelect } from '../../../molecules/controls/ValidationSelect/ValidationSelect';
import { ExpressionField } from '../../../atoms/ExpressionField/ExpressionField';
const EMPTY_FIELDS = [];
export const ConfigureFields = (props) => {
    const { fieldConfiguration, domain, setFieldConfiguration, addFieldConfigurationMember, setFieldConfigurationMembersFromGridData, saveFieldConfiguration, saveFieldConfigurationMember, resetFieldConfiguration, className, } = props;
    const [areFieldsDirty, setAreFieldsDirty] = React.useState(false);
    const dirtinessScope = React.useRef(null);
    const fieldDirtinessScope = React.useRef(null);
    const [selectedFcm, setSelectedFcm] = React.useState();
    const { promptAndContinue, forceReset } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    // tracks the fields outside of the grid
    useDirtinessSync({
        scope: fieldDirtinessScope,
        isDirty: areFieldsDirty,
        onReset: resetFieldConfiguration,
        onSave: saveFieldConfiguration,
    });
    /**
     * Generate Context Menu items for grid.
     * @param params AgGrids Context Menu params.
     */
    const getContextMenuItems = (params) => {
        return [
            {
                name: 'Edit',
                action: () => promptAndContinue(() => {
                    var _a;
                    setSelectedFcm((_a = params.node) === null || _a === void 0 ? void 0 : _a.data);
                }),
            },
        ];
    };
    /**
     * User clicked the cancel button in the Panel.
     * Close panel and call callback.
     */
    const handleFormCancel = () => {
        resetFieldConfiguration();
        setSelectedFcm(undefined);
    };
    /**
     * Closes the form but does not call the callback for cancel.
     * To be used after save.
     */
    const handleFormClose = () => {
        setSelectedFcm(undefined);
    };
    /**
     * User selected the Save button in the Panel.
     * Map values back to FieldConfigurationMember and call callback.
     * @param values The formdata contained in the Panel.
     * @returns a promise boolean indicating success
     */
    const handleFormSubmit = (values, suppressClose) => __awaiter(void 0, void 0, void 0, function* () {
        const result = cloneDatum(values);
        if (result.inputType !== InputType.Picklist)
            result.picklistField = undefined;
        const successfulSubmit = yield saveFieldConfigurationMember(values);
        forceReset();
        if (successfulSubmit && !suppressClose)
            setSelectedFcm(undefined);
        if (!successfulSubmit)
            throw new Error('Field configuration save failed');
        else
            setSelectedFcm(values);
    });
    const { fields } = useConfigureFieldsMeta({
        domain,
    });
    const fieldMembers = fieldConfiguration.fieldConfigurationMembers || EMPTY_FIELDS;
    const handleValidationChange = (validationViewId) => {
        setAreFieldsDirty(true);
        setFieldConfiguration(Object.assign(Object.assign({}, fieldConfiguration), { validationViewId }));
    };
    const handleValidationChangeForDeleteExpression = (deleteExpression) => {
        setAreFieldsDirty(true);
        setFieldConfiguration(Object.assign(Object.assign({}, fieldConfiguration), { deleteExpression: String(deleteExpression) }));
    };
    const handleValidationChangeForDeleteExpressionDescription = (deleteExpressionDescription) => {
        setAreFieldsDirty(true);
        setFieldConfiguration(Object.assign(Object.assign({}, fieldConfiguration), { deleteExpressionDescription: String(deleteExpressionDescription) }));
    };
    const [css] = useStyletron();
    return (React.createElement("div", { className: ClassNameBuilder(className, css({ display: 'flex', flexDirection: 'column', overflow: 'hidden' })), ref: dirtinessScope },
        React.createElement("div", { ref: fieldDirtinessScope },
            React.createElement(ValidationSelect, { domainId: fieldConfiguration.domainId, validationViewId: fieldConfiguration.validationViewId, onChange: handleValidationChange }),
            React.createElement("div", { className: css({ width: '300px', marginBottom: '10px' }) },
                React.createElement(FieldLabel, { labelText: "Delete Description" }),
                React.createElement(TextEntryField, { value: fieldConfiguration.deleteExpressionDescription || '', onChange: handleValidationChangeForDeleteExpressionDescription, name: "", onBlur: () => '' }),
                React.createElement(FieldLabel, { labelText: "Delete Expression", tooltipText: "If the expression results in True, then the record can be deleted." }),
                React.createElement(ExpressionField, { domainId: fieldConfiguration.domainId, value: fieldConfiguration.deleteExpression || '', onChange: handleValidationChangeForDeleteExpression }))),
        React.createElement("div", { className: css({ flex: 1 }) },
            React.createElement(Grid, { onSubmit: saveFieldConfiguration, onBeforeReset: resetFieldConfiguration, key: fieldConfiguration === null || fieldConfiguration === void 0 ? void 0 : fieldConfiguration.id, isEditingDefault: true, suppressEditToggle: true, onClickAddRow: addFieldConfigurationMember, suppressAddRowButton: true, suppressDeleteButton: true, suppressClearFilter: true, suppressExcelExport: true, suppressRowSpacing: true, suppressDeleteConfirmationModal: true, data: fieldMembers, fields: fields, idField: "viewFieldName", onChangeData: setFieldConfigurationMembersFromGridData, getContextMenuItems: getContextMenuItems, stopEditingWhenCellsLoseFocus: true })),
        domain && (React.createElement(FieldConfigurationCrudEditorPanel, { formData: selectedFcm, onSubmit: handleFormSubmit, isOpen: !!selectedFcm, onCancel: handleFormCancel, onClose: handleFormClose, domain: domain, fieldConfiguration: fieldConfiguration }))));
};
export default ConfigureFields;
