/* eslint-disable react/jsx-props-no-spreading */
import { MapToListViewMember, useDomain, useListView, } from '@samc/screen-config-api';
import { Spinner } from '@fluentui/react';
import React from 'react';
import { MessageBar } from '@samc/react-ui-core';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { usePreparedEvaluatorConfigurationByDomain } from '../../../hooks/usePreparedEvaluatorConfigurationByDomain/usePreparedEvaluatorConfigurationByDomain';
import QueryExecuteManager from '../../../molecules/QueryExecuteManager/QueryExecuteManager';
import { useMissingViewDependencies } from '../../../hooks/useMissingViewDependencies/useMissingViewDependencies';
import { useGridInjector } from '../../../hooks/useGridInjector/useGridInjector';
import { useGridFieldsFromListview } from '../../../hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import { BaseRecordDropdown } from '../BaseRecordDropdown/BaseRecordDropdown';
import { LONG_NAME_FIELD } from '../Constants';
const ListViewRecordDropdownWithDomain = (props) => {
    const { filters, missingViewMembers, domain, doFetch, listView } = props;
    const { primaryKey: primaryKeyField } = domain;
    const { frameworkComponents, wrappedFields, isLoading, fieldConfigurationMembers } = useGridFieldsFromListview({
        listView,
        additionalMembers: missingViewMembers,
        filters,
        suppressLinks: true,
        useGridInjector,
    });
    const longNameField = fieldConfigurationMembers[LONG_NAME_FIELD];
    const pkField = fieldConfigurationMembers[primaryKeyField];
    const { viewFieldName: displayFieldName } = longNameField || pkField;
    if (isLoading)
        return React.createElement(Spinner, { label: "Loading grid fields...", labelPosition: "right" });
    if (!wrappedFields)
        return React.createElement(MessageBar, { text: "Failed to load grid fields" });
    return (React.createElement(BaseRecordDropdown, Object.assign({}, props, { frameworkComponents: frameworkComponents, doFetch: doFetch, fields: wrappedFields, primaryKeyField: primaryKeyField, displayFieldName: displayFieldName })));
};
const ListViewRecordDropdownWithView = (props) => {
    const { listView, filters: inputFilters, listViewId } = props;
    const { domainId, defaultFilter } = listView;
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const evaluatorConfig = usePreparedEvaluatorConfigurationByDomain(domainId);
    const { missingViewMembers: missingFieldDependencies, isLoading: areMissingViewMembersLoading } = useMissingViewDependencies(listView, listView.listViewMembers, MapToListViewMember);
    const filters = React.useMemo(() => {
        const output = [];
        if (defaultFilter)
            output.push(defaultFilter);
        if (inputFilters)
            output.push(...inputFilters);
        return output;
    }, [defaultFilter, inputFilters]);
    const adhocListViewMembers = React.useMemo(() => missingFieldDependencies.map((listViewMember) => ({
        scalarExpression: `[${listViewMember.viewFieldName}]`,
        scalarDisplayName: `${listViewMember.viewFieldName}`,
    })), [missingFieldDependencies]);
    if (isDomainLoading)
        return React.createElement(Spinner, { label: "Loading domain...", labelPosition: "right" });
    if (!domain)
        return React.createElement(MessageBar, { text: "Failed to load domain" });
    if (areMissingViewMembersLoading)
        return React.createElement(Spinner, { label: "Loading view members...", labelPosition: "right" });
    return (React.createElement(EvaluatorConfigurationProvider, { value: evaluatorConfig },
        React.createElement(QueryExecuteManager, { domainId: listView.domainId, listViewId: listViewId, filters: filters, defaultSortParameters: listView.defaultSortOrder, adhocListViewMembers: adhocListViewMembers }, (queryRenderProps) => (React.createElement(ListViewRecordDropdownWithDomain, Object.assign({}, props, { filters: filters, missingViewMembers: missingFieldDependencies, doFetch: queryRenderProps.doFetch, domain: domain }))))));
};
export const ListViewRecordDropdown = (props) => {
    const { listViewId } = props;
    const { data: listView, isLoading: isListViewLoading } = useListView(listViewId);
    if (isListViewLoading)
        return React.createElement(Spinner, { label: "Loading view...", labelPosition: "right" });
    if (!listView)
        return React.createElement(MessageBar, { text: "Failed to load view" });
    return React.createElement(ListViewRecordDropdownWithView, Object.assign({}, props, { listView: listView }));
};
export default ListViewRecordDropdown;
