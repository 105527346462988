var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Modal } from '@fluentui/react';
import { ViewType } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { HeaderContextProvider, useHeaderContext, } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import { AddDocumentsWrapper } from '../DocumentsModal/AddDocumentsWrapper/AddDocumentsWrapper';
import { AddDocumentLinks } from '../DocumentsModal/AddDocumentLinks/AddDocumentLinks';
import { ModalControlBar } from '../ModalControlBar/ModalControlBar';
import { ControlBarActions, } from '../../actions/ControlBarActions/ControlBarActions';
import { isAddDocumentView, isDocumentLinkView } from '../DocumentsModal/DocumentsModal.Utilities';
import { findRequestWithIdentifier, findResponseWithIdentifier, getFormRequestIdentifier, isSuccessfulResponse, } from '../../FormScreen/utilities';
let _ViewModal;
/**
 * The most basic internal contents of a ViewModal. Renders a grid or form and handles
 * submission. Needs to be wrapped in a view and header context.
 */
export const ViewModalContents = (props) => {
    const { viewId, viewType, filters, defaultData, primaryKeyValue, children, showContent, style, sidePanelFormViewId, onSaved, setPrimaryKeyValue, dismissOnSave, onDismiss, onSubmodalSaved, GridScreenWrapper, FormScreenWrapper, ServiceDrivenViewSet, } = props;
    const [css] = useStyletron();
    const handleFormSubmit = React.useCallback((_, taskRequests, taskResponses) => {
        const rootIdentifier = getFormRequestIdentifier();
        const rootResponse = findResponseWithIdentifier(rootIdentifier, taskResponses);
        const rootRequest = findRequestWithIdentifier(rootIdentifier, taskRequests);
        if (rootResponse && rootRequest && setPrimaryKeyValue && viewType !== ViewType.Grid && !primaryKeyValue) {
            const { payload } = rootRequest;
            const { newIds } = rootResponse;
            const oldPk = Number(Object.keys(payload)[0]);
            if (Number.isNaN(oldPk))
                return;
            const newPrimaryKeyValue = newIds[oldPk];
            if (newPrimaryKeyValue)
                setPrimaryKeyValue(String(newPrimaryKeyValue));
        }
        const { isSuccessful } = isSuccessfulResponse(taskResponses);
        if (isSuccessful) {
            onSaved === null || onSaved === void 0 ? void 0 : onSaved();
            if (dismissOnSave && onDismiss)
                onDismiss();
        }
    }, [dismissOnSave, onDismiss, onSaved, primaryKeyValue, setPrimaryKeyValue, viewType]);
    const handleGridSubmit = React.useCallback(() => {
        onSaved === null || onSaved === void 0 ? void 0 : onSaved();
        if (dismissOnSave && onDismiss)
            onDismiss();
    }, [dismissOnSave, onDismiss, onSaved]);
    return viewType === ViewType.Grid ? (React.createElement(GridScreenWrapper, { onSubmit: handleGridSubmit, style: style, showContent: showContent, key: viewId, listViewId: viewId, filters: filters, ServiceDrivenViewSet: ServiceDrivenViewSet }, children)) : (React.createElement(FormScreenWrapper, { showContent: showContent, className: css({ flex: 1 }), style: style, key: viewId, formViewId: viewId, onSubmit: handleFormSubmit, filters: filters, defaultData: defaultData, primaryKeyValue: primaryKeyValue, sidePanelForm: sidePanelFormViewId, onModalSaved: onSubmodalSaved, submitAllAttributes // modals, for now, should submit entire payload
        : true, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper }, children));
};
/**
 * The ViewModal implementation that still needs to be wrapped in a context
 * provider.
 */
const UnwrappedViewModal = (props) => {
    const { isOpen, onDismiss, onSaved, headerText, primaryKeyValue, suppressSaveButton, submitDisabledOverride, actions, GridScreenWrapper, FormScreenWrapper, ServiceDrivenViewSet, } = props;
    const dirtinessScope = React.useRef(null);
    const { promptAndContinue, save: controlbarSubmitForm, isDirty, } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const { controlBarProps } = useHeaderContext();
    const { submitDisabled } = controlBarProps !== null && controlBarProps !== void 0 ? controlBarProps : {};
    const submitForm = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (controlbarSubmitForm)
            yield controlbarSubmitForm();
        if (onSaved)
            onSaved();
    }), [controlbarSubmitForm, onSaved]);
    /**
     * Forcefully closes the modal, does not prompt
     */
    const forceClose = React.useCallback(() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(), [onDismiss]);
    /**
     * To be run after a delete action completes
     */
    const afterDelete = React.useCallback(() => {
        if (onDismiss)
            onDismiss(); // after a delete action, the record the modal had selected is gone, close modal
        if (onSaved)
            onSaved();
    }, [onDismiss, onSaved]);
    /**
     * User dismissed the modal, uses prompt
     * Trigger the callback.
     */
    const handleCancel = React.useCallback(
    // automatically manages reset with promptAndContinue
    () => promptAndContinue(forceClose), [forceClose, promptAndContinue]);
    const customizedActions = React.useMemo(() => {
        const result = Object.assign({}, actions);
        if (actions && actions.actionItems) {
            result.actionItems = actions.actionItems.map((action) => (Object.assign(Object.assign({}, action), { primaryKeyValue })));
        }
        return result;
    }, [actions, primaryKeyValue]);
    return (React.createElement(Modal, { layerProps: { eventBubblingEnabled: true }, isOpen: isOpen, onDismiss: handleCancel, isBlocking: false, forceFocusInsideTrap: false },
        React.createElement("div", { className: "view-modal", ref: dirtinessScope, style: { height: '85vh', width: '85vw', display: 'flex', flexDirection: 'column' } },
            React.createElement(ModalControlBar, Object.assign({ headerText: headerText, onDismiss: forceClose, cancel: handleCancel, suppressSaveButton: suppressSaveButton }, controlBarProps, { submit: submitForm, submitDisabled: submitDisabledOverride !== null && submitDisabledOverride !== void 0 ? submitDisabledOverride : (submitDisabled || !isDirty), additionalControls: React.createElement(ControlBarActions, Object.assign({ ViewModal: _ViewModal, ServiceDrivenViewSet: ServiceDrivenViewSet, GridScreenWrapper: GridScreenWrapper, FormScreenWrapper: FormScreenWrapper, dirtinessScope: dirtinessScope, afterDelete: afterDelete }, customizedActions)) })),
            React.createElement(ViewModalContents, Object.assign({}, props)))));
};
_ViewModal = Object.assign((props) => {
    const { viewId, onDismiss: onDismissProp, filters, isOpen, onSaved: onSavedProp, showContent, defaultFilterExpression, parentPk, parentForm, primaryKeyValue, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const hasBeenSaved = React.useRef(false);
    const onSaved = React.useCallback(() => {
        hasBeenSaved.current = true;
        if (onSavedProp)
            onSavedProp();
    }, [onSavedProp]);
    const onDismiss = React.useCallback(() => onDismissProp(hasBeenSaved.current), [onDismissProp]);
    if (isAddDocumentView(viewId)) {
        return (React.createElement(AddDocumentsWrapper, { viewId: viewId, onDismiss: onDismiss, primaryKeyValue: parentPk, filters: filters, isOpen: isOpen, onSaved: onSaved, showContent: showContent, defaultFilterExpression: defaultFilterExpression, additionalKeyValue: primaryKeyValue, parentForm: parentForm, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }
    if (isDocumentLinkView(viewId)) {
        return (React.createElement(AddDocumentLinks, { viewId: viewId, entityId: parentPk, onDismiss: onDismiss, onSaved: onSaved, isOpen: isOpen, filters: filters, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }));
    }
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(UnwrappedViewModal, Object.assign({}, props, { onDismiss: onDismiss, onSaved: onSaved }))));
}, { Contents: ViewModalContents });
/**
 * Launches a modal with either a Grid or Form
 * @param props
 * @returns
 */
export const ViewModal = _ViewModal;
export default ViewModal;
